import React from 'react'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoginForm5 from '../main_components/loginFormSimple'

import Logo4 from '../img/logo1.png'

export default function Login() {
  return (
    <div>
        <Container>
          <Row>
            <Col>
              <img id="logo-login" src={Logo4} alt="Logo4" />
            </Col>
          </Row>
          <h2>Revierpark Wischlingen</h2>
        </Container>
        
        <LoginForm5 locationID="RPW" />
    </div>
  )
}
