import React, {useState, useEffect} from 'react'
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Config from '../Config/Config.json'
import {useLocation} from 'react-router-dom'
import axios from 'axios';
import socket from '../../socket'
import { v4 as uuidv4 } from "uuid";
import Loading from '../../main_components/spinner'
  

export default function Parkplatzbereich() {

  const [isLoading, setIsLoading] = useState(false)
  const [vorgang, setVorgang] = useState("solved")
  const location = useLocation()
  const [UUID, setUUID] = useState(location.state.UUID)
  const [warning, setWarning] = useState(false)
  const [dimmVal1, setDimmVal1] = useState(10)
  const [dimmVal2, setDimmVal2] = useState(10)
  const [dimmVal3, setDimmVal3] = useState(10)
  const [einOffset, seteinOffset] = useState(0)
  const [einfix, setEinfix] = useState("00:00:00")
  const [einOffset2, seteinOffset2] = useState(0)
  const [lower1, setLower1] = useState("00:00:00")
  const [lower2, setLower2] = useState("00:00:00")
  const [lower3, setLower3] = useState("00:00:00")
  const [rise, setRise] = useState("00:00:00")
  const [einfix2, setEinfix2] = useState("00:00:00")
  const [sonnen, setsonnen] = useState("outline-success")
  const [fix, setfix] = useState("success")
  const [isFix, setIsFix] = useState(false)
  const [config, setConfig] = useState({
    "StandardDimmWerte": Config.StandardDimmWerte.Parkplatzbeleuchtung,
    "StandardZeitEinstellung": Config.StandardZeitEinstellung.Parkplatzbeleuchtung
  })

  useEffect(() => { compareValues() }, [isFix, dimmVal1, dimmVal2, dimmVal3, einOffset, lower1, lower2, lower3, einfix, einOffset2, einfix2])
  const [Counter, setCounter] = useState(0)
  useEffect(() => {var Cnt = Counter; Counter <= 1 ? setCounter(Cnt+=1) : postData()}, [einfix,
    lower1,
    lower2,
    lower3,
    rise,
    einfix2])

  const handleSonne = (event) => {
    if (event.target.id === "Sonne") {
      setIsFix(false)
      postData2(false)
      setsonnen("success")
      setfix("outline-success")
    } else {
      setIsFix(true)
      postData2(true)
      setsonnen("outline-success")
      setfix("success")
    }
  }

  useEffect(()=>{
    socket.get_solved((data) => {
      if(vorgang === data.postID){
        if(data.isSolved){
          setIsLoading(false)
          setVorgang("solved")
          socket.close()
        }else{
          alert("Keine Verbindung zum Server!")
          setIsLoading(false)
          setVorgang("solved")
          socket.close()
        }
      }
    })
  },[vorgang])

  useEffect(() => {
    socket.get_socket_data((data) => {
      if (data.name === "RPM" && data.table === "parkplatzbereich3") {
        setIsFix(!!data.row.IsFixedTime)
        setDimmVal1(data.row.DimmlevelToNightLower)
        setDimmVal2(data.row.DimmLevelNightLower)
        setDimmVal3(data.row.DimmlevelAtNightRise)
        seteinOffset(data.row.SunSetOffset)
        setEinfix(data.row.FixOnOne)
        seteinOffset2(data.row.SunRiseOffset)
        setEinfix2(data.row.FixOffOne)
        setLower1(data.row.	FixNightLowerMODO)
        setLower2(data.row.	FixNightLowerFRSA)
        setLower3(data.row.	FixNightLowerSO)
        setRise(data.row.	FixNightRiseAllDays)
        if (!!data.row.IsFixedTime) {
          setsonnen("outline-success")
          setfix("success")
        } else {
          setsonnen("success")
          setfix("outline-success")
        }
      }
    })
  }, [])

  useEffect(() => {
    axios.get(`https://${window.location.hostname}:443/getData`, {
      params: {
        UUID: UUID,
        stID: "RPM",
        areaID: "parkplatzbereich3"
      }
    })
      .then((res) => {
        if (res.status !== 200) {
          alert(res.statusText)
          return
        }
        setIsFix(!!res.data.IsFixedTime)
        setDimmVal1(res.data.DimmlevelToNightLower)
        setDimmVal2(res.data.DimmLevelNightLower)
        setDimmVal3(res.data.DimmlevelAtNightRise)
        seteinOffset(res.data.SunSetOffset)
        setEinfix(res.data.FixOnOne)
        seteinOffset2(res.data.SunRiseOffset)
        setEinfix2(res.data.FixOffOne)
        setLower1(res.data.FixNightLowerMODO)
        setLower1(res.data.FixNightLowerFRSA)
        setLower3(res.data.FixNightLowerSO)
        setRise(res.data.FixNightRiseAllDays)
        if (!!res.data.IsFixedTime) {
          setsonnen("outline-success")
          setfix("success")
        } else {
          setsonnen("success")
          setfix("outline-success")
        }
      })
      .catch((err) => alert(err))
    
  }, [])

  const compareValues = () => {
    if (
      isFix
      || dimmVal1 !== Config.StandardDimmWerte.Parkplatzbeleuchtung3.DimmlevelBisNachtabsenkung
      || dimmVal2 !== Config.StandardDimmWerte.Parkplatzbeleuchtung3.DimmlevelNachtabsenkung
      || dimmVal3 !== Config.StandardDimmWerte.Parkplatzbeleuchtung3.DimmlevelNachterhöhung
      || einOffset !== Config.StandardZeitEinstellung.Parkplatzbeleuchtung3.Ein
      || lower1 !== Config.StandardZeitEinstellung.Parkplatzbeleuchtung3.NachtabsenkungMODO + ":00"
      || lower2 !== Config.StandardZeitEinstellung.Parkplatzbeleuchtung3.NachtabsenkungFRSA + ":00"
      || lower3 !== Config.StandardZeitEinstellung.Parkplatzbeleuchtung3.NachtabsenkungSO + ":00"
      || einOffset2 !== Config.StandardZeitEinstellung.Parkplatzbeleuchtung3.Aus
    ) {
      setWarning(true)
    } else {
      setWarning(false)
    }
  }

  const postData = () => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPM",
      areaID: "parkplatzbereich3",
      postID: postID,
      UUID: UUID,
      data: {
        IsFixedTime: isFix,
        SunSetOffset: einOffset,
        SunRiseOffset: einOffset2,
        FixOnOne: einfix,
        FixOffOne: einfix2,
        FixNightLowerMODO: lower1,
        FixNightLowerFRSA: lower2,
        FixNightLowerSO: lower3,
        FixNightRiseAllDays: rise,
        DimmlevelToNightLower: dimmVal1,
        DimmLevelNightLower: dimmVal2,
        DimmlevelAtNightRise: dimmVal3,
      }
    })
    .catch((err) => { alert(err) })
  }
  const postData2 = (val) => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPM",
      areaID: "parkplatzbereich3",
      postID: postID,
      UUID: UUID,
      data: {
        IsFixedTime: val,
        SunSetOffset: einOffset,
        SunRiseOffset: einOffset2,
        FixOnOne: einfix,
        FixOffOne: einfix2,
        FixNightLowerMODO: lower1,
        FixNightLowerFRSA: lower2,
        FixNightLowerSO: lower3,
        FixNightRiseAllDays: rise,
        DimmlevelToNightLower: dimmVal1,
        DimmLevelNightLower: dimmVal2,
        DimmlevelAtNightRise: dimmVal3,
      }
    })
    .catch((err) => { alert(err) })
  }
  const ResetData = () => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPM",
      areaID: "parkplatzbereich3",
      postID: postID,
      UUID: UUID,
      data: {
        IsFixedTime: false,
        SunSetOffset: Config.StandardZeitEinstellung.Parkplatzbeleuchtung3.Ein,
        SunRiseOffset: Config.StandardZeitEinstellung.Parkplatzbeleuchtung3.Aus,
        FixOnOne: einfix,
        FixOffOne: einfix2,
        FixNightLowerMODO: Config.StandardZeitEinstellung.Parkplatzbeleuchtung3.NachtabsenkungMODO,
        FixNightLowerFRSA: Config.StandardZeitEinstellung.Parkplatzbeleuchtung3.NachtabsenkungFRSA,
        FixNightLowerSO: Config.StandardZeitEinstellung.Parkplatzbeleuchtung3.NachtabsenkungSO,
        FixNightRiseAllDays: Config.StandardZeitEinstellung.Parkplatzbeleuchtung3.Nachterhöhung,
        DimmlevelToNightLower: Config.StandardDimmWerte.Parkplatzbeleuchtung3.DimmlevelBisNachtabsenkung,
        DimmLevelNightLower: Config.StandardDimmWerte.Parkplatzbeleuchtung3.DimmlevelNachtabsenkung,
        DimmlevelAtNightRise: Config.StandardDimmWerte.Parkplatzbeleuchtung3.DimmlevelNachterhöhung,
      }
    })
    .catch((err) => { alert(err) })
  }

  return (
    <>
          {
        isLoading?(<Loading />):(<></>)
      }
      <Container>
        <Row>
          <Col className="mt-3">
            <Alert variant="success">Parkplatzbeleuchtung 3</Alert>
          </Col>
        </Row>
        {
          warning ?
            (
              <>
                <h4 style={{ color: 'red' }}>!!! Achtung Beleuchtung im unerlaubten Bereich !!!</h4>
              </>
            ) : (<></>)
        }
        <Row>
          <Col className='d-grid'>
            <h5 style={{ marginBlockEnd: "0px" }}>Zeitmodus:</h5>
            <ButtonGroup aria-label="Basic example" className="mt-4">
              <Button variant={sonnen} id="Sonne" onClick={handleSonne}>
                Sonnenstand
              </Button>
              <Button variant={fix} id="Fix" onClick={handleSonne}>
                Fixe Zeiten
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <div className="Seperator mt-3"></div>
        <Row>
          <Col>
            {sonnen == "success" ? (
              <div>
                <h5>Einschaltzeit:</h5>
                <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Offset Sonnenuntergang: " + einOffset + "min"}</p>
                <input type="range" min="-400" max="400" step="5" value={einOffset} onChange={(e) => seteinOffset(e.target.value)} onMouseUp={postData} className='dimm' />
              </div>
            ) : (
              <div>
                <h5>Einschaltzeit:</h5>
                <input type="time" value={einfix} onChange={(e) => { setEinfix(e.target.value);}}></input><br /><br />
              </div>
            )}

          </Col>
          <Col className='d-grid'>
            <h5>Nachtabsenkung Mo - Do:</h5>
            <input style={{height: "30px"}} type="time" value={lower1} onChange={(e) => { setLower1(e.target.value);}}></input><br /><br />
          </Col>
          <Col className='d-grid'>
            <h5>Nachtabsenkung Fr - Sa:</h5>
            <input style={{height: "30px"}} type="time" value={lower2} onChange={(e) => { setLower2(e.target.value);}}></input><br /><br />
          </Col>
          <Col className='d-grid'>
            <h5>Nachtabsenkung So:</h5>
            <input style={{height: "30px"}} type="time" value={lower3} onChange={(e) => { setLower3(e.target.value);}}></input><br /><br />
          </Col>
          <Col className='d-grid'>
            <h5>Nachterhöhung:</h5>
            <input style={{height: "30px"}} type="time" value={rise} onChange={(e) => { setRise(e.target.value);}}></input><br /><br />
          </Col>
          <Col>
            {sonnen == "success" ? (
              <div>
                <h5>Ausschaltzeit:</h5>
                <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Offset Sonnenaufgang: " + einOffset2 + "min"}</p>
                <input type="range" min="-400" max="400" step="5" value={einOffset2} onChange={(e) => seteinOffset2(e.target.value)} onMouseUp={postData} className='dimm' />
              </div>
            ) : (
              <div>
                <h5>Ausschaltzeit:</h5>
                <input type="time" value={einfix2} onChange={(e) => { setEinfix2(e.target.value);}}></input><br /><br />
              </div>
            )}

          </Col>
        </Row>
        <h5>Bitte beachten die bei der Eingabe von festen Zeitpunkten, dass der Sonnenuntergang und Sonnenaufgang über das Jahr hinweg sich verändert. Sollte der Auf- oder Untergangs Zeitpunkt die fixen Zeiten überschreiten, kann dies zu unerwünschten Verhalten der Lichtanlage führen.</h5>
        <div className="Seperator mt-3"></div>
        <Row>
          <Col className='d-grid gap-2 mt-3'>
            <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Helligkeit bis Nachtabsenkung: " + dimmVal1 + "%"}</p>
            <input type="range" min="0" max="100" value={dimmVal1} onChange={(e) => setDimmVal1(e.target.value)} onMouseUp={postData} className='dimm' />
          </Col>
        </Row>
        <Row>
          <Col className='d-grid gap-2 mt-3'>
            <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Helligkeit Nachtabsenkung: " + dimmVal2 + "%"}</p>
            <input type="range" min="0" max="100" value={dimmVal2} onChange={(e) => setDimmVal2(e.target.value)} onMouseUp={postData} className='dimm' />
          </Col>
        </Row>
        <Row>
          <Col className='d-grid gap-2 mt-3'>
            <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Helligkeit Nachterhöhung: " + dimmVal3 + "%"}</p>
            <input type="range" min="0" max="100" value={dimmVal3} onChange={(e) => setDimmVal3(e.target.value)} onMouseUp={postData} className='dimm' />
          </Col>
        </Row>
        <div className="Seperator mt-3"></div>
        <Row>
          <Col className="d-grid gap-2 mt-3">
            <Button variant="danger" size="lg" onClick={ResetData}>
              Reset auf Standardwerte für Parkplatzbeleuchtung 3
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}
