import React from 'react'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoginForm1 from '../main_components/loginFormSimple'

import Logo2 from '../img/logo1.png'

export default function Login() {
  return (
    <div>
        <Container>
          <Row>
            <Col>
              <img id="logo-login" src={Logo2} alt="Logo2" />
            </Col>
          </Row>
          <h2>Revierpark Gysenberg</h2>
        </Container>
        
        <LoginForm1 locationID="RPG" />
    </div>
  )
}