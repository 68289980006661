import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";

function CollapsibleExample(props) {
  const [UUID, setUUID] = useState(props.UUID);
  const history = useNavigate();

  const Logout = () =>{
    axios.post(`https://` + window.location.hostname + `:443/logout`, { UUID: UUID, stID: "RPW" })
    .then(res=>{
      if(res.status !== 200){
        alert(res.statusText)
      }
    })
    .catch(err => console.error(err))
    .finally(() =>{
      history("/login_"+props.location)
    })
  }

  return (
    <Navbar collapseOnSelect expand="lg" bg="success" variant="dark">
      <Container>
        <Navbar.Brand onClick={() => history("/RPW/Allgemein", { state: { UUID: UUID } })}>
        Wischlingen
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={() => history("/RPW/Allgemein", { state: { UUID: UUID } })}>
              Allgemein
            </Nav.Link>
            <NavDropdown title="Steuerung" id="collasible-nav-dropdown">
              <NavDropdown.Item onClick={() => history("/RPW/Anleuchtung_R", { state: { UUID: UUID } })}>
                Anleuchtung R
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => history("/RPW/Farbige_Beleuchtung", { state: { UUID: UUID } })}
              >
                Farbige Beleuchtung
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => history("/RPW/Parkplatzbeleuchtung", { state: { UUID: UUID } })}
              >
                Parkplatzbeleuchtung
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => history("/RPW/Spielplatzbereich", { state: { UUID: UUID } })}
              >
                Spielplatzbereich
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => history("/RPW/Sonderbereich", { state: { UUID: UUID } })}
              >
                Sonderbereich
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => history("/RPW/Schwimmstegbeleuchtung", { state: { UUID: UUID } })}
              >
                Schwimmstegbeleuchtung
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => history("/RPW/Wegebeleuchtung_weiß", { state: { UUID: UUID } })}
              >
                Wegebeleuchtung weiß
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link onClick={() => history("/RPW/Fehlerübersicht", { state: { UUID: UUID } })}>
              Fehlerübersicht
            </Nav.Link>
            <Nav.Link onClick={() => history("/RPW/User_management", { state: { UUID: UUID } })}>
              Benutzerverwaltung
            </Nav.Link>
          </Nav>
          <Nav>
            <Button variant="danger" onClick={Logout}>
              Logout
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;
