import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SunCalc from "suncalc";
import { useLocation } from 'react-router-dom'
import Config from '../Config/Config.json'
import socket from '../../socket'
import axios from 'axios';
import { v4 as uuidv4 } from "uuid";
import Loading from '../../main_components/spinner'


export default function Allgemein() {

  const location = useLocation()
  const [UUID, setUUID] = useState(location.state.UUID)
  const [vorgang, setVorgang] = useState("solved")
  const [isLoading, setIsLoading] = useState(false)
  const [msgWarning, setMsgWarning] = useState(false)

  const [noColor, setNoColor] = useState(false)
  const [colorOn, setColorOn] = useState(false)
  const [white100, setWhite100] = useState(false)

  const [config, setConfig] = useState({
    "StandardDimmWerte": Config.StandardDimmWerte.Sportplatzbeleuchtung,
    "StandardZeitEinstellung": Config.StandardZeitEinstellung.Sportplatzbeleuchtung
  })

  useEffect(() => {
    axios.get(`https://${window.location.hostname}:443/getMSG`, {
      params: {
        UUID: UUID,
        stID: "RPN",
      }
    })
      .then((res) => {
        if (res.status !== 200) {
          alert(res.statusText)
          return
        }
        if (res.data.length > 0) {
          setMsgWarning(true)
        }
      })
      .catch((err) => alert(err))
  }, [])
  useEffect(() => {
    socket.get_messages((res) => {
      var data = res.data
      if (data.standortID === "RPN") {
        if (data.msg.length > 0) {
          setMsgWarning(true)
        } else {
          setMsgWarning(false)
        }
      }
    })
  }, [])

  const today = new Date();
  const [time, setTime] = useState(
    new Date().toLocaleTimeString().split(":").slice(0, 2).join(":")
  );
  const [sunrise, setSunrise] = useState(
    getSunrise(51.5407, 7.0816, today)
  );
  const [sunset, setSunset] = useState(
    getSunsetTime(51.5407, 7.0816, today)
  );

  useEffect(() => {
    socket.get_solved((data) => {
      if (vorgang === data.postID) {
        if (data.isSolved) {
          setIsLoading(false)
          setVorgang("solved")
          socket.close()
        } else {
          alert("Keine Verbindung zum Server!")
          setIsLoading(false)
          setVorgang("solved")
          socket.close()
        }
      }
    })
  }, [vorgang])

  useEffect(() => {
    socket.get_socket_data((data) => {
      if (data.name === "RPN" && data.table === "buttons") {
        console.log(data)
        setNoColor(!!data.row.NoColor)
        setColorOn(!!data.row.ColorOn)
        setWhite100(!!data.row.White100)
      }
    })
  }, [])

  useEffect(() => {
    axios.get(`https://${window.location.hostname}:443/getData`, {
      params: {
        UUID: UUID,
        stID: "RPN",
        areaID: "buttons"
      }
    })
      .then((res) => {
        if (res.status !== 200) {
          alert(res.statusText)
          return
        }
        setNoColor(!!res.data.NoColor)
        setColorOn(!!res.data.ColorOn)
        setWhite100(!!res.data.White100)
      })
      .catch((err) => alert(err))
  }, [])


  useEffect(() => {
    const Intervall = setInterval(() => {
      setTime(today.toLocaleTimeString().split(":").slice(0, 2).join(":"));
      const sunriseTime = getSunrise(51.5407, 7.0816, today);
      const sunsetTime = getSunsetTime(51.5407, 7.0816, today);
      setSunrise(sunriseTime);
      setSunset(sunsetTime);
    }, 1000 * 30);
    return () => {
      clearInterval(Intervall);
    };
  }, []);

  function getSunrise(latitude, longitude, date) {
    const latRad = (Math.PI * latitude) / 180;
    const lonRad = (Math.PI * longitude) / 180;
    const julianDate = date.getTime() / 86400000 + 2440587.5;
    const sunriseTime = SunCalc.getTimes(julianDate, latRad, lonRad).sunrise;
    const sunriseTimeString = sunriseTime.toLocaleTimeString("de-DE", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return sunriseTimeString;
  }

  function getSunsetTime(lat, lon, date) {
    const latRad = (Math.PI * lat) / 180;
    const lonRad = (Math.PI * lon) / 180;
    const julianDate = date.getTime() / 86400000 + 2440587.5;
    const sunsetTime = SunCalc.getTimes(julianDate, latRad, lonRad).sunset;
    const sunriseTimeString = sunsetTime.toLocaleTimeString("de-DE", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return sunriseTimeString;
  }

  const postData = (ID) => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPN",
      areaID: "buttons",
      UUID: UUID,
      postID: postID,
      data: {
        NoColor: ID === "NC" ? (noColor ? false : true) : noColor,
        ColorOn: ID === "CO" ? (colorOn ? false : true) : colorOn,
        White100: ID === "W1" ? (white100 ? false : true) : white100,
      }
    })
      .catch((err) => { alert(err) })
  }

  const ResetAll = () => {
    var postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)

    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPN",
      areaID: "anleuchtung_r",
      UUID: UUID,
      postID: postID,
      data: {
        IsFixedTime: false,
        SunSetOffset: Config.StandardZeitEinstellung.Anleuchtung_R.Ein,
        SunRiseOffset: Config.StandardZeitEinstellung.Anleuchtung_R.Aus,
        FixOffOne: Config.StandardZeitEinstellung.Anleuchtung_R.FixAus,
        FixOnTwo: Config.StandardZeitEinstellung.Anleuchtung_R.FixEin,
        Dimmlevel: Config.StandardDimmWerte.Anleuchtung_R.Dimmlevel
      }
    })
      .catch((err) => { alert(err) })
      .finally(async() => {
        postID = uuidv4();
        setVorgang(postID)
        setIsLoading(true)
        await new Promise(resolve => setTimeout(resolve, 200));
        axios.post(`https://${window.location.hostname}:443/postData`, {
          stID: "RPN",
          areaID: "farbige_beleuchtung",
          postID: postID,
          UUID: UUID,
          data: {
            IsFixedTime: false,
            SummerSunSetOffsetOn: Config.StandardZeitEinstellung.Farbige_Beleuchtung.Somme_Ein,
            SummerSunSetOffsetOff: Config.StandardZeitEinstellung.Farbige_Beleuchtung.Sommer_Aus,
            WinterSunSetOffsetOn: Config.StandardZeitEinstellung.Farbige_Beleuchtung.Winter_Ein,
            WinterSunSetOffsetOff: Config.StandardZeitEinstellung.Farbige_Beleuchtung.Winter_Aus,
            Dimmlevel: Config.StandardDimmWerte.Farbige_Beleuchtung.Dimmlevel
          }
        })
          .catch((err) => { alert(err) })
          .finally(async() => {
            postID = uuidv4();
            setVorgang(postID)
            setIsLoading(true)
            await new Promise(resolve => setTimeout(resolve, 200));
            axios.post(`https://${window.location.hostname}:443/postData`, {
              stID: "RPN",
              areaID: "parkplatzbereich",
              postID: postID,
              UUID: UUID,
              data: {
                IsFixedTime: false,
                SunSetOffset: Config.StandardZeitEinstellung.Parkplatzbeleuchtung.Ein,
                SunRiseOffset: Config.StandardZeitEinstellung.Parkplatzbeleuchtung.Aus,
                FixOffOne: Config.StandardZeitEinstellung.Parkplatzbeleuchtung.Nachtabsenkung,
                FixOnTwo: Config.StandardZeitEinstellung.Parkplatzbeleuchtung.Nachterhöhung,
                DimmlevelToNightLower: Config.StandardDimmWerte.Parkplatzbeleuchtung.DimmlevelBisNachtabsenkung,
                DimmlevelAtNightLower: Config.StandardDimmWerte.Parkplatzbeleuchtung.DimmlevelNachtabsenkung,
                DimmlevelAtNightRise: Config.StandardDimmWerte.Parkplatzbeleuchtung.DimmlevelNachterhöhung,
              }
            })
              .catch((err) => { alert(err) })
              .finally(async() => {
                postID = uuidv4();
                setVorgang(postID)
                setIsLoading(true)
                await new Promise(resolve => setTimeout(resolve, 200));
                axios.post(`https://${window.location.hostname}:443/postData`, {
                  stID: "RPN",
                  areaID: "sportplatzbeleuchtung",
                  postID: postID,
                  UUID: UUID,
                  data: {
                    IsFixedTime: false,
                    Dimmlevel: Config.StandardDimmWerte.Sportplatzbeleuchtung.Dimmlevel,
                    SunSetOffset: Config.StandardZeitEinstellung.Sportplatzbeleuchtung.Ein,
                    FixOffOne: Config.StandardZeitEinstellung.Sportplatzbeleuchtung.Aus,
                    ActiveDuration: Config.StandardZeitEinstellung.Sportplatzbeleuchtung.Aktivierung,
                  }
                })
                  .catch((err) => { alert(err) })
                  .finally(async() => {
                    postID = uuidv4();
                    setVorgang(postID)
                    setIsLoading(true)
                    await new Promise(resolve => setTimeout(resolve, 200));
                    axios.post(`https://${window.location.hostname}:443/postData`, {
                      stID: "RPN",
                      areaID: "wegebeleuchtung_weiß",
                      postID: postID,
                      UUID: UUID,
                      data: {
                        IsFixedTime: false,
                        DimmlevelToNightLower: Config.StandardDimmWerte.Wegebeleuchtung_Weiß.DimmlevelBisNachtabsenkung,
                        DimmlevelAtNightLower: Config.StandardDimmWerte.Wegebeleuchtung_Weiß.DimmlevelNachtabsenkung,
                        LowerLevel: Config.StandardDimmWerte.Wegebeleuchtung_Weiß.Absenklevel,
                        SunSetOffset: Config.StandardZeitEinstellung.Wegebeleuchtung_Weiß.Ein,
                        FixNightLower: Config.StandardZeitEinstellung.Wegebeleuchtung_Weiß.Nachtabsenkung + ":00",
                        FixLowerDuration: Config.StandardZeitEinstellung.Wegebeleuchtung_Weiß.Absenkzeit,
                        SunRiseOffset: Config.StandardZeitEinstellung.Wegebeleuchtung_Weiß.Aus,
                      }
                    })
                      .catch((err) => { alert(err) })
                      .finally(async() => {
                        postID = uuidv4();
                        setVorgang(postID)
                        setIsLoading(true)
                        await new Promise(resolve => setTimeout(resolve, 200));
                        axios.post(`https://${window.location.hostname}:443/postData`, {
                          stID: "RPN",
                          areaID: "buttons",
                          UUID: UUID,
                          postID: postID,
                          data: {
                            NoColor: false,
                            ColorOn: false,
                            White100: false,
                          }
                        })
                          .catch((err) => { alert(err) })
                      })
                  })
              })
          })
      })
  }

  return (
    <>
      {
        isLoading ? (<Loading />) : (<></>)
      }
      <Container>
        <Row>
          <Col className="mt-3">
            <Alert variant="success">Allgemein</Alert>
          </Col>
        </Row>
        {
          msgWarning ? (<>
            <h5 style={{ color: "red" }}>
              Achtung: Es bestehen DALI Meldungen, bitte überprüfen sie diese auf der Fehlerübersicht!
            </h5>
          </>) : (<></>)
        }
        <Row>
          <Col>
            <h5>Aktuelle Uhrzeit: </h5>
            <h5>{time}</h5>
          </Col>
          <p>
            Die Uhrzeit wird von der Systemzeit ihres Gerätes genommen. Sie
            stimmt nur, wenn ihr Gerät auf die richtige Zeit und das richtige
            Datum gestellt ist!
          </p>
        </Row>
        <div className="Seperator"></div>
        <Row>
          <Col className="mt-4 gap-2 d-grid">
            <Button variant={noColor ? "success" : "dark"} id="NC" onClick={(e) => { postData(e.target.id); }}>Keine Farbige Beleuchtung</Button>
          </Col>
          <Col className="mt-4 gap-2 d-grid">
            <Button variant={colorOn ? "success" : "dark"} id="CO" onClick={(e) => { postData(e.target.id); }}>Farbige Beleuchtung Ein</Button>
          </Col>
          <Col className="mt-4 gap-2 d-grid">
            <Button variant={white100 ? "success" : "dark"} id="W1" onClick={(e) => { postData(e.target.id); }}>Weißes Licht Ein 100%</Button>
          </Col>
        </Row>
        <div className="Seperator mt-4"></div>
        <Row>
          <Col className="d-grid gap-2 mt-3">
            <Button variant="danger" size="lg" onClick={ResetAll}>
              Reset auf Standardwerte für alle Einstellungen
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
