import React from 'react'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoginForm from '../main_components/loginForm'

import Logo1 from '../img/logo1.png'
import Logo3 from '../img/logo3.png'
import Logo4 from '../img/logo4.png'

export default function Login() {
  return (
    <div>
        <Container>
          <Row>
            <Col>
              <img id="logo-login" src={Logo1} alt="Logo1" />
              <img id="logo-login" src={Logo3} alt="Logo3" />
              <img id="logo-login" src={Logo4} alt="Logo4" />
            </Col>
          </Row>
        </Container>
        
        <LoginForm />
    </div>
  )
}
