import React, {useState, useEffect} from 'react'
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Config from '../Config/Config.json'
import {useLocation} from 'react-router-dom'
import axios from 'axios';
import { v4 as uuidv4 } from "uuid";
import socket from '../../socket'
import Loading from '../../main_components/spinner'
  

export default function Wegebeleuchtung_weiß() {
  
  const [isLoading, setIsLoading] = useState(false)
  const [vorgang, setVorgang] = useState("solved")
  const location = useLocation()
  const [UUID, setUUID] = useState(location.state.UUID)
  const [config, setConfig] = useState({
    "StandardDimmWerte": Config.StandardDimmWerte.Wegebeleuchtung_Weiß,
    "StandardZeitEinstellung": Config.StandardZeitEinstellung.Wegebeleuchtung_Weiß
  })
  const [warning, setWarning] = useState(false)
  const [dimmVal1, setDimmVal1] = useState(10)
  const [dimmVal2, setDimmVal2] = useState(10)
  const [dimmVal3, setDimmVal3] = useState(10)

  const [einOffset, seteinOffset] = useState(0)
  const [einfix1, seteinfix1] = useState("00:00:00")

  const [lower1, setlower1] = useState("00:00:00")
  const [lower2, setlower2] = useState("00:00:00")

  const [einOffset2, seteinOffset2] = useState(0)

  const [einOffset3, seteinOffset3] = useState(0)
  const [einfix2, seteinfix2] = useState("00:00:00")

  const [sonnen, setsonnen] = useState("outline-success")
  const [fix, setfix] = useState("success")
  const [isFix, setIsFix] = useState(false)

  useEffect(() => { compareValues() }, [isFix, dimmVal1, dimmVal2, dimmVal3, einOffset, einfix1, lower1, lower2, einOffset2, einOffset3, einfix2])
  const [Counter, setCounter] = useState(0)
  useEffect(() => {var Cnt = Counter; Counter <= 1 ? setCounter(Cnt+=1) : postData()}, [einfix1,
    lower1,
    lower2,
    einfix2])

  useEffect(()=>{
    socket.get_solved((data) => {
      if(vorgang === data.postID){
        if(data.isSolved){
          setIsLoading(false)
          setVorgang("solved")
          socket.close()
        }else{
          alert("Keine Verbindung zum Server!")
          setIsLoading(false)
          setVorgang("solved")
          socket.close()
        }
      }
    })
  },[vorgang])

  const handleSonne = (event) => {
    if (event.target.id === "Sonne") {
      setsonnen("success")
      setIsFix(false)
      postData2(false)
      setfix("outline-success")
    } else {
      setsonnen("outline-success")
      setIsFix(true)
      postData2(true)
      setfix("success")
    }
  }

  useEffect(() => {
    socket.get_socket_data((data) => {
      if (data.name === "RPM" && data.table === "wegebeleuchtung_weiß") {
        setIsFix(!!data.row.IsFixedTime)
        setDimmVal1(data.row.DimmlevelToNightLower)
        setDimmVal2(data.row.DimmlevelAtNightLower)
        setDimmVal3(data.row.LowerLevel)
        seteinOffset(data.row.SunSetOffset)
        seteinfix1(data.row.FixOnOne)
        setlower1(data.row.FixNightLowerSommer)
        setlower2(data.row.FixNightLowerWinter)
        seteinOffset2(data.row.FixLowerDuration)
        seteinOffset3(data.row.SunRiseOffset)
        seteinfix2(data.row.FixOffOne)
        if (!!data.row.IsFixedTime) {
          setsonnen("outline-success")
          setfix("success")
        } else {
          setsonnen("success")
          setfix("outline-success")
        }
      }
    })
  }, [])

  useEffect(() => {
    axios.get(`https://${window.location.hostname}:443/getData`, {
      params: {
        UUID: UUID,
        stID: "RPM",
        areaID: "wegebeleuchtung_weiß"
      }
    })
      .then((res) => {
        if (res.status !== 200) {
          alert(res.statusText)
          return
        }
        setIsFix(!!res.data.IsFixedTime)
        setDimmVal1(res.data.DimmlevelToNightLower)
        setDimmVal2(res.data.DimmlevelAtNightLower)
        setDimmVal3(res.data.LowerLevel)
        seteinOffset(res.data.SunSetOffset)
        seteinfix1(res.data.FixOnOne)
        setlower1(res.data.FixNightLowerSommer)
        setlower2(res.data.FixNightLowerWinter)
        seteinOffset2(res.data.FixLowerDuration)
        seteinOffset3(res.data.SunRiseOffset)
        seteinfix2(res.data.FixOffOne)
        if (!!res.data.IsFixedTime) {
          setsonnen("outline-success")
          setfix("success")
        } else {
          setsonnen("success")
          setfix("outline-success")
        }
      })
      .catch((err) => alert(err))
    
  }, [])

  const compareValues = () => {
    if (
      isFix
      || dimmVal1 !== Config.StandardDimmWerte.Wegebeleuchtung_Weiß.DimmlevelBisNachtabsenkung
      || dimmVal2 !== Config.StandardDimmWerte.Wegebeleuchtung_Weiß.DimmlevelNachtabsenkung
      || dimmVal3 !== Config.StandardDimmWerte.Wegebeleuchtung_Weiß.Absenklevel
      || einOffset !== Config.StandardZeitEinstellung.Wegebeleuchtung_Weiß.Ein
      || lower1 !== Config.StandardZeitEinstellung.Wegebeleuchtung_Weiß.NachtabsenkungSommer + ":00"
      || lower2 !== Config.StandardZeitEinstellung.Wegebeleuchtung_Weiß.NachtabsenkungWinter + ":00"
      || einOffset2 !== Config.StandardZeitEinstellung.Wegebeleuchtung_Weiß.Absenkzeit
      || einOffset3 !== Config.StandardZeitEinstellung.Wegebeleuchtung_Weiß.Aus
    ) {
      setWarning(true)
    } else {
      setWarning(false)
    }
  }

  const ResetData = () => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPM",
      areaID: "wegebeleuchtung_weiß",
      postID: postID,
      UUID: UUID,
      data: {
        IsFixedTime: false,
        DimmlevelToNightLower: Config.StandardDimmWerte.Wegebeleuchtung_Weiß.DimmlevelBisNachtabsenkung,
        DimmlevelAtNightLower: Config.StandardDimmWerte.Wegebeleuchtung_Weiß.DimmlevelNachtabsenkung,
        LowerLevel: Config.StandardDimmWerte.Wegebeleuchtung_Weiß.Absenklevel,
        SunSetOffset: Config.StandardZeitEinstellung.Wegebeleuchtung_Weiß.Ein,
        FixOnOne: einfix1,
        FixNightLowerSommer: Config.StandardZeitEinstellung.Wegebeleuchtung_Weiß.NachtabsenkungSommer + ":00",
        FixNightLowerWinter: Config.StandardZeitEinstellung.Wegebeleuchtung_Weiß.NachtabsenkungWinter + ":00",
        FixLowerDuration: Config.StandardZeitEinstellung.Wegebeleuchtung_Weiß.Absenkzeit,
        SunRiseOffset: Config.StandardZeitEinstellung.Wegebeleuchtung_Weiß.Aus,
        FixOffOne: einfix2,
      }
    })
    .catch((err) => { alert(err) })
  }

  const postData = () => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPM",
      areaID: "wegebeleuchtung_weiß",
      postID: postID,
      UUID: UUID,
      data: {
        IsFixedTime: isFix,
        DimmlevelToNightLower: dimmVal1,
        DimmlevelAtNightLower: dimmVal2,
        LowerLevel: dimmVal3,
        SunSetOffset: einOffset,
        FixOnOne: einfix1,
        FixNightLowerSommer: lower1,
        FixNightLowerWinter: lower2,
        FixLowerDuration: einOffset2,
        SunRiseOffset: einOffset3,
        FixOffOne: einfix2
      }
    })
    .catch((err) => { alert(err) })
  }
  const postData2 = (val) => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPM",
      areaID: "wegebeleuchtung_weiß",
      postID: postID,
      UUID: UUID,
      data: {
        IsFixedTime: val,
        DimmlevelToNightLower: dimmVal1,
        DimmlevelAtNightLower: dimmVal2,
        LowerLevel: dimmVal3,
        SunSetOffset: einOffset,
        FixOnOne: einfix1,
        FixNightLowerSommer: lower1,
        FixNightLowerWinter: lower2,
        FixLowerDuration: einOffset2,
        SunRiseOffset: einOffset3,
        FixOffOne: einfix2
      }
    })
    .catch((err) => { alert(err) })
  }

  return (
    <>
          {
        isLoading?(<Loading />):(<></>)
      }
      <Container>
        <Row>
          <Col className="mt-3">
            <Alert variant="success">Wegebeleuchtung weiß</Alert>
          </Col>
        </Row>
        {
          warning ?
            (
              <>
                <h4 style={{ color: 'red' }}>!!! Achtung Beleuchtung im unerlaubten Bereich !!!</h4>
              </>
            ) : (<></>)
        }
        <Row>
          <Col className='d-grid'>
            <h5 style={{ marginBlockEnd: "0px" }}>Zeitmodus:</h5>
            <ButtonGroup aria-label="Basic example" className="mt-4">
              <Button variant={sonnen} id="Sonne" onClick={handleSonne}>
                Sonnenstand
              </Button>
              <Button variant={fix} id="Fix" onClick={handleSonne}>
                Fixe Zeiten
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <div className="Seperator mt-3"></div>
        <Row>
          <Col>
            {sonnen == "success" ? (
              <div>
                <h5>Einschaltzeit:</h5>
                <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Offset Sonnenuntergang: " + einOffset + "min"}</p>
                <input type="range" min="-400" max="400" step="5" onMouseUp={postData} value={einOffset} onChange={(e) => seteinOffset(e.target.value)} className='dimm' />
              </div>
            ) : (
              <div>
                <h5>Einschaltzeit:</h5>
                <input type="time" value={einfix1} onChange={(e) => { seteinfix1(e.target.value);}}></input><br /><br />
              </div>
            )}

          </Col>
          <Col className='d-grid gap-2'>
            <h5>Nachtabsenkung Sommer:</h5>
            <input style={{height: "30px"}} type="time" value={lower1} onChange={(e) => { setlower1(e.target.value);}}></input><br /><br />
          </Col>
          <Col className='d-grid gap-2'>
            <h5>Nachtabsenkung Winter:</h5>
            <input style={{height: "30px"}} type="time" value={lower2} onChange={(e) => { setlower2(e.target.value);}}></input><br /><br />
          </Col>
          <Col>
            <h5>Absenkzeit:</h5>
            <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Absenkung Nach: " + einOffset2 + "min"}</p>
            <input type="range" min="0" max="100" step="5" value={einOffset2} onMouseUp={postData} onChange={(e) => seteinOffset2(e.target.value)} className='dimm' />
          </Col>
          <Col>
            {sonnen == "success" ? (
              <div>
                <h5>Ausschaltzeit:</h5>
                <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Offset Sonnenaufgang: " + einOffset3 + "min"}</p>
                <input type="range" min="-400" max="400" step="5" value={einOffset3} onMouseUp={postData} onChange={(e) => seteinOffset3(e.target.value)} className='dimm' />
              </div>
            ) : (
              <div>
                <h5>Ausschaltzeit:</h5>
                <input type="time" value={einfix2} onChange={(e) => { seteinfix2(e.target.value);}}></input><br /><br />
              </div>
            )}

          </Col>
        </Row>
        <h5>Bitte beachten die bei der Eingabe von festen Zeitpunkten, dass der Sonnenuntergang und Sonnenaufgang über das Jahr hinweg sich verändert. Sollte der Auf- oder Untergangs Zeitpunkt die fixen Zeiten überschreiten, kann dies zu unerwünschten Verhalten der Lichtanlage führen.</h5>
        <div className="Seperator mt-3"></div>
        <Row>
          <Col className='d-grid gap-2 mt-3'>
            <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Helligkeit bis Nachtabsenkung: " + dimmVal1 + "%"}</p>
            <input type="range" min="0" max="100" value={dimmVal1} onMouseUp={postData} onChange={(e) => setDimmVal1(e.target.value)} className='dimm' />
          </Col>
        </Row>
        <Row>
          <Col className='d-grid gap-2 mt-3'>
            <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Helligkeit Nachtabsenkung: " + dimmVal2 + "%"}</p>
            <input type="range" min="0" max="100" value={dimmVal2} onMouseUp={postData} onChange={(e) => setDimmVal2(e.target.value)} className='dimm' />
          </Col>
        </Row>
        <Row>
          <Col className='d-grid gap-2 mt-3'>
            <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Absenklevel: " + dimmVal3 + "%"}</p>
            <input type="range" min="0" max="100" value={dimmVal3} onMouseUp={postData} onChange={(e) => setDimmVal3(e.target.value)} className='dimm' />
          </Col>
        </Row>
        <div className="Seperator mt-3"></div>
        <Row>
          <Col className="d-grid gap-2 mt-3">
            <Button variant="danger" size="lg" onClick={ResetData}>
              Reset auf Standardwerte für Wegebeleuchtung weiß
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}
