import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import CryptoJS from "crypto-js";

import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";

function BasicExample(props) {
  const [locationID, setLocationID] = useState(props.locationID);
  const history = useNavigate();

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  const UUID = uuidv4();

  const [SALT, setSALT] = useState("");

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [user, password]);

  useEffect(() => {
    axios
      .get(`https://` + window.location.hostname + `:443/getSalt`)
      .then((res) => {
        if ("Salt" in res.data) {
          setSALT(res.data.Salt);
        }
      });
  }, []);

  const handleSubmit = (event) => {
    if (user === "") {
      alert("Bitte geben sie einen Benutzer ein!");
      return;
    }
    if (password === "") {
      alert("Bitte geben sie ein Passwort ein!");
      return;
    }
    var algo = CryptoJS.algo.SHA256.create();
    algo.update(password, "utf-8");
    algo.update(CryptoJS.SHA256(SALT), "utf-8");
    var hash = algo.finalize().toString(CryptoJS.enc.Base64);
    axios
      .post(`https://` + window.location.hostname + `:443/login`, {
        user: user,
        password: hash,
        stID: locationID,
        UUID: UUID,
      })
      .then((res) => {
        if ("message" in res.data) {
          alert(res.data.message);
          return;
        }
        history("/" + locationID + "/Allgemein", { state: { UUID: UUID } });
      })
      .catch((err) => console.error(err));
  };

  const handleUser = (event) => {
    setUser(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  return (
    <Row className="justify-content-md-center">
      <Col md="auto">
        <Form>
          <Form.Group className="mb-3 mt-5" controlId="formBasicEmail">
            <Form.Label>Email Adresse</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              onChange={handleUser}
            />
            <Form.Text className="text-muted">
              Never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Passwort</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={handlePassword}
            />
            <Form.Text className="text-muted">
              Never share your password with anyone else.
            </Form.Text>
          </Form.Group>
          <Button variant="primary" onClick={handleSubmit}>
            Login
          </Button>
        </Form>
      </Col>
    </Row>
  );
}

export default BasicExample;
