import io from 'socket.io-client';

const socket = (() => {
    let solvedSocket = null;

    const collector = (callback) => {

        const socket = io('https://revierpark-lichtsteuerung.de');

        socket.on('dataChange', (data) => {
            callback(data);
        });

    }

    const MSGcollector = (callback) => {

        const socket = io('https://revierpark-lichtsteuerung.de');

        socket.on('fixtureMSG', (data) => {
            callback(data);
        });

    }

    const solver = (callback) => {

        if (solvedSocket) {
            solvedSocket.close();
        }

        solvedSocket = io('https://revierpark-lichtsteuerung.de');

        solvedSocket.on('solving', (data) => {
            callback(data);
        });

    }

    const close = () => {

        if (solvedSocket) {
          solvedSocket.close();
          solvedSocket = null;
        }

    };

    return{
        get_socket_data: (callback)=>{collector(callback)},
        get_solved: (callback)=>{solver(callback)},
        get_messages: (callback)=>{MSGcollector(callback)},
        close: close,
    }
})();

export default socket;