import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";

function CollapsibleExample(props) {
  const history = useNavigate();
  const [UUID, setUUID] = useState(props.UUID);

  const Logout = () =>{
    axios.post(`https://` + window.location.hostname + `:443/logout`,{UUID:UUID, stID:"RPM"})
    .then(res=>{
      if(res.status !== 200){
        alert(res.statusText)
      }
    })
    .catch(err => console.error(err))
    .finally(() =>{
      history("/login_"+props.location)
    })
  }

  return (
    <Navbar collapseOnSelect expand="lg" bg="success" variant="dark">
      <Container>
        <Navbar.Brand onClick={() => history("/RPM/Allgemein", {state:{UUID:UUID}})}>
        Mattlerbusch
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={() => history("/RPM/Allgemein", {state:{UUID:UUID}})}>
              Allgemein
            </Nav.Link>
            <NavDropdown title="Steuerung" id="collasible-nav-dropdown">
              <NavDropdown.Item onClick={() => history("/RPM/Anleuchtung_R", {state:{UUID:UUID}})}>
                Anleuchtung R
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => history("/RPM/Farbige_Beleuchtung", {state:{UUID:UUID}})}
              >
                Farbige Beleuchtung
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => history("/RPM/Wegebeleuchtung_weiß", {state:{UUID:UUID}})}
              >
                Wegebeleuchtung weiß
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => history("/RPM/Sportplatzbeleuchtung", {state:{UUID:UUID}})}
              >
                Sportplatzbeleuchtung
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => history("/RPM/Parkplatzbeleuchtung1", {state:{UUID:UUID}})}
              >
                Parkplatzbeleuchtung 1
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => history("/RPM/Parkplatzbeleuchtung2", {state:{UUID:UUID}})}
              >
                Parkplatzbeleuchtung 2
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => history("/RPM/Parkplatzbeleuchtung3", {state:{UUID:UUID}})}
              >
                Parkplatzbeleuchtung 3
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link onClick={() => history("/RPM/Fehlerübersicht", {state:{UUID:UUID}})}>
              Fehlerübersicht
            </Nav.Link>
            <Nav.Link onClick={() => history("/RPM/User_management", {state:{UUID:UUID}})}>
              Benutzerverwaltung
            </Nav.Link>
          </Nav>
          <Nav>
            <Button variant="danger" onClick={Logout}>
              Logout
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;
