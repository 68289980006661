import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { createHashRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./main_components/error-page";

import Login from "./main_pages/login";
import RPGLogin from './main_pages/loginRPG'
import RPMLogin from './main_pages/loginRPM'
import RPNLogin from './main_pages/loginRPN'
import RPVLogin from './main_pages/loginRPV'
import RPWLogin from './main_pages/loginRPW'

import RPG from "./RPG/RPG";
import RPGAllgemein from "./RPG/Pages/Allgemein";
import UserRPG from "./RPG/Pages/Benutzerverwaltung";
import RPGAnleuchtung from './RPG/Pages/Anleuchtung_R'
import RPGFarbig from './RPG/Pages/Farbige_Beleuchtung'
import RPGSport from './RPG/Pages/Sportplatzbeleuchtung'
import RPGWege from './RPG/Pages/Wegebeleuchtung_weiß'
import RPGWegeSonder from './RPG/Pages/Wegebeleuchtung_weiß_sonder'
import RPGFehler from "./RPG/Pages/Fehlerübersicht";

import RPM from "./RPM/RPM";
import RPMAllgemein from "./RPM/Pages/Allgemein";
import RPMFarbig from "./RPM/Pages/Farbige_Beleuchtung";
import UserRPM from "./RPM/Pages/Benutzerverwaltung";
import RPMWege from "./RPM/Pages/Wegebeleuchtung_weiß";
import RPMAnleuchtung from "./RPM/Pages/Anleuchtung_R";
import RPMParkplatz1 from "./RPM/Pages/Parkplatzbereich1"
import RPMParkplatz2 from "./RPM/Pages/Parkplatzbereich2"
import RPMParkplatz3 from "./RPM/Pages/Parkplatzbereich3"
import RPMFehler from "./RPM/Pages/Fehlerübersicht";
import RPMSport from './RPM/Pages/Sportplatzbeleuchtung'

import RPN from "./RPN/RPN";
import RPNAllgemein from "./RPN/Pages/Allgemein";
import RPNAnleuchtung from "./RPN/Pages/Anleuchtung_R";
import RPNFarbig from "./RPN/Pages/Farbige_Beleuchtung";
import RPNFehler from "./RPN/Pages/Fehlerübersicht";
import RPNParkplatz from "./RPN/Pages/Parkplatzbereich";
import RPNSport from "./RPN/Pages/Sportplatzbeleuchtung";
import RPNWege from "./RPN/Pages/Wegebeleuchtung_weiß";
import UserRPN from "./RPN/Pages/Benutzerverwaltung";

import RPV from "./RPV/RPV";
import RPVAllgemein from "./RPV/Pages/Allgemein";
import UserRPV from "./RPV/Pages/Benutzerverwaltung";
import RPVAnleuchtung from './RPV/Pages/Anleuchtung_R'
import RPVParkplatz from './RPV/Pages/Parkplatzbereich'
import RPVSport from './RPV/Pages/Sportplatzbeleuchtung'
import RPVTreppen from './RPV/Pages/Treppenlicht'
import RPVWege from './RPV/Pages/Wegebeleuchtung_weiß'
import RPVFehler from "./RPV/Pages/Fehlerübersicht";
import RPVFarbig from "./RPV/Pages/Farbige_Beleuchtung";

import RPW from "./RPW/RPW";
import RPWAllgemein from "./RPW/Pages/Allgemein";
import UserRPW from "./RPW/Pages/Benutzerverwaltung";
import RPWAnleuchtung from './RPW/Pages/Anleuchtung_R'
import RPWFarbig from './RPW/Pages/Farbige_beleuchtung'
import RPWParkplatz from './RPW/Pages/Parkplatzbereich'
import RPWSchwimm from './RPW/Pages/Schwimmstegbeleuchtung'
import RPWSonder from './RPW/Pages/Sonderbereich'
import RPWSpielplatz from './RPW/Pages/Spielplatz'
import RPWWege from './RPW/Pages/Wegebeleuchtung_weiß'
import RPWFehler from "./RPW/Pages/Fehlerübersicht";

function App(){
  const router = createHashRouter([
    {
      path: "/administration_login",
      element: <Login />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/login_RPG",
      element: <RPGLogin />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/login_RPM",
      element: <RPMLogin />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/login_RPN",
      element: <RPNLogin />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/login_RPV",
      element: <RPVLogin />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/login_RPW",
      element: <RPWLogin />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/RPG",
      element: <RPG />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "Allgemein",
          element: <RPGAllgemein />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Anleuchtung_R",
          element: <RPGAnleuchtung />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Farbige_Beleuchtung",
          element: <RPGFarbig />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Sportplatzbeleuchtung",
          element: <RPGSport />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Wegebeleuchtung_weiß_Sonder",
          element: <RPGWegeSonder />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Wegebeleuchtung_weiß",
          element: <RPGWege />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Fehlerübersicht",
          element: <RPGFehler />,
          errorElement: <ErrorPage />,
        },
        {
          path: "User_management",
          element: <UserRPG />,
          errorElement: <ErrorPage />
        }
      ],
    },
    {
      path: "/RPM",
      element: <RPM />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "Allgemein",
          element: <RPMAllgemein />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Anleuchtung_R",
          element: <RPMAnleuchtung  />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Parkplatzbeleuchtung1",
          element: <RPMParkplatz1 />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Parkplatzbeleuchtung2",
          element: <RPMParkplatz2 />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Wegebeleuchtung_weiß",
          element: <RPMWege />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Parkplatzbeleuchtung3",
          element: <RPMParkplatz3 />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Farbige_Beleuchtung",
          element: <RPMFarbig />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Fehlerübersicht",
          element: <RPMFehler />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Sportplatzbeleuchtung",
          element: <RPMSport />,
          errorElement: <ErrorPage />,
        },
        {
          path: "User_management",
          element: <UserRPM />,
          errorElement: <ErrorPage />
        }
      ],
    },
    {
      path: "/RPN",
      element: <RPN />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "Allgemein",
          element: <RPNAllgemein />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Anleuchtung_R",
          element: <RPNAnleuchtung  />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Farbige_Beleuchtung",
          element: <RPNFarbig />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Fehlerübersicht",
          element: <RPNFehler />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Parkplatzbeleuchtung",
          element: <RPNParkplatz />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Sportplatzbeleuchtung",
          element: <RPNSport />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Wegebeleuchtung_weiß",
          element: <RPNWege />,
          errorElement: <ErrorPage />,
        },
        {
          path: "User_management",
          element: <UserRPN />,
          errorElement: <ErrorPage />
        }
      ],
    },
    {
      path: "/RPV",
      element: <RPV />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "Allgemein",
          element: <RPVAllgemein />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Anleuchtung_R",
          element: <RPVAnleuchtung />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Parkplatzbeleuchtung",
          element: <RPVParkplatz />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Sportplatzbeleuchtung",
          element: <RPVSport />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Treppenbereich",
          element: <RPVTreppen />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Wegebeleuchtung_weiß",
          element: <RPVWege />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Fehlerübersicht",
          element: <RPVFehler />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Farbige_Beleuchtung",
          element: <RPVFarbig />,
          errorElement: <ErrorPage />,
        },
        {
          path: "User_management",
          element: <UserRPV />,
          errorElement: <ErrorPage />
        }
      ],
    },
    {
      path: "/RPW",
      element: <RPW />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "Allgemein",
          element: <RPWAllgemein />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Anleuchtung_R",
          element: <RPWAnleuchtung />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Farbige_Beleuchtung",
          element: <RPWFarbig />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Parkplatzbeleuchtung",
          element: <RPWParkplatz />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Schwimmstegbeleuchtung",
          element: <RPWSchwimm />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Sonderbereich",
          element: <RPWSonder />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Spielplatzbereich",
          element: <RPWSpielplatz />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Wegebeleuchtung_weiß",
          element: <RPWWege />,
          errorElement: <ErrorPage />,
        },
        {
          path: "Fehlerübersicht",
          element: <RPWFehler />,
          errorElement: <ErrorPage />,
        },
        {
          path: "User_management",
          element: <UserRPW />,
          errorElement: <ErrorPage />
        }
      ],
    },
  ]);
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
