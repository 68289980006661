import React, { useState, useEffect } from 'react'
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import socket from '../../socket'
import Loading from '../../main_components/spinner'
import { v4 as uuidv4 } from "uuid";

import Config from '../Config/Config.json'

export default function Anleuchtung_R() {

  const location = useLocation()
  const [UUID, setUUID] = useState(location.state.UUID)
  const [config, setConfig] = useState({
    "StandardDimmWerte": Config.StandardDimmWerte.Anleuchtung_R,
    "StandardZeitEinstellung": Config.StandardZeitEinstellung.Anleuchtung_R
  })
  const [warning, setWarning] = useState(false)
  const [dimmVal, setDimmVal] = useState(100)
  const [einOffset, seteinOffset] = useState(20)
  const [fixeinOffset, setFixeinOffset] = useState("00:00:00")
  const [ausOffset, setausOffset] = useState("00:00:00")
  const [einOffset2, seteinOffset2] = useState(-20)
  const [fixeinOffset2, setFixeinOffset2] = useState("00:00:00")
  const [ausOffset2, setausOffset2] = useState("05:00:00")
  const [sonnen, setsonnen] = useState("outline-success")
  const [fix, setfix] = useState("success")
  const [isFix, setIsFix] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [vorgang, setVorgang] = useState("solved")

  useEffect(() => { compareValues() }, [isFix, dimmVal, einOffset, ausOffset, ausOffset2, einOffset2])
  const [Counter, setCounter] = useState(0)
  useEffect(() => {var Cnt = Counter; Counter <= 1 ? setCounter(Cnt+=1) : postData()}, [fixeinOffset, ausOffset, ausOffset2, fixeinOffset2])

  const handleSonne = (event) => {
    if (event.target.id === "Sonne") {
      setsonnen("success")
      setIsFix(false)
      postData2(false)
      setfix("outline-success")
    } else {
      setsonnen("outline-success")
      setIsFix(true)
      postData2(true)
      setfix("success")
    }
  }

  useEffect(()=>{
    socket.get_solved((data) => {
      console.log(data.postID+" : "+vorgang)
      if(vorgang === data.postID){
        if(data.isSolved){
          setIsLoading(false)
          setVorgang("solved")
          socket.close()
        }else{
          alert("Keine Verbindung zum Server!")
          setIsLoading(false)
          setVorgang("solved")
          socket.close()
        }
      }
    })
  },[vorgang])

  useEffect(() => {
    socket.get_socket_data((data) => {
      if (data.name === "RPV" && data.table === "anleuchtung_r") {
        setIsFix(!!data.row.IsFixedTime)
        setDimmVal(data.row.Dimmlevel)
        seteinOffset(data.row.SunSetOffset)
        setFixeinOffset(data.row.FixOnOne)
        setausOffset(data.row.FixOffOne)
        seteinOffset2(data.row.SunRiseOffset)
        setFixeinOffset2(data.row.FixOffTwo)
        setausOffset2(data.row.FixOnTwo)
        if (!!data.row.IsFixedTime) {
          setsonnen("outline-success")
          setfix("success")
        } else {
          setsonnen("success")
          setfix("outline-success")
        }
      }
    })
  }, [])

  useEffect(() => {
    axios.get(`https://${window.location.hostname}:443/getData`, {
      params: {
        UUID: UUID,
        stID: "RPV",
        areaID: "anleuchtung_r"
      }
    })
      .then((res) => {
        if (res.status !== 200) {
          alert(res.statusText)
          return
        }
        setIsFix(!!res.data.IsFixedTime)
        setDimmVal(res.data.Dimmlevel)
        seteinOffset(res.data.SunSetOffset)
        setFixeinOffset(res.data.FixOnOne)
        setausOffset(res.data.FixOffOne)
        seteinOffset2(res.data.SunRiseOffset)
        setFixeinOffset2(res.data.FixOffTwo)
        setausOffset2(res.data.FixOnTwo)
        if (!!res.data.IsFixedTime) {
          setsonnen("outline-success")
          setfix("success")
        } else {
          setsonnen("success")
          setfix("outline-success")
        }
      })
      .catch((err) => alert(err))
    
  }, [])

  const compareValues = () => {
    if (
      isFix
      || dimmVal !== Config.StandardDimmWerte.Anleuchtung_R.Dimmlevel
      || einOffset !== Config.StandardZeitEinstellung.Anleuchtung_R.Ein
      || ausOffset !== Config.StandardZeitEinstellung.Anleuchtung_R.FixAus + ":00"
      || ausOffset2 !== Config.StandardZeitEinstellung.Anleuchtung_R.FixEin + ":00"
      || einOffset2 !== Config.StandardZeitEinstellung.Anleuchtung_R.Aus
    ) {
      setWarning(true)
    } else {
      setWarning(false)
    }
  }

  const postData = () => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPV",
      areaID: "anleuchtung_r",
      postID: postID,
      UUID: UUID,
      data: {
        IsFixedTime: isFix,
        SunSetOffset: einOffset,
        SunRiseOffset: einOffset2,
        FixOnOne: fixeinOffset,
        FixOffOne: ausOffset,
        FixOnTwo: ausOffset2,
        FixOffTwo: fixeinOffset2,
        Dimmlevel: dimmVal
      }
    })
    .catch((err) => { alert(err) })
  }
  const postData2 = (val) => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPV",
      areaID: "anleuchtung_r",
      postID: postID,
      UUID: UUID,
      data: {
        IsFixedTime: val,
        SunSetOffset: einOffset,
        SunRiseOffset: einOffset2,
        FixOnOne: fixeinOffset,
        FixOffOne: ausOffset,
        FixOnTwo: ausOffset2,
        FixOffTwo: fixeinOffset2,
        Dimmlevel: dimmVal
      }
    })
    .catch((err) => { alert(err) })
  }
  const ResetData = () => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPV",
      areaID: "anleuchtung_r",
      UUID: UUID,
      postID: postID,
      data: {
        IsFixedTime: false,
        SunSetOffset: Config.StandardZeitEinstellung.Anleuchtung_R.Ein,
        SunRiseOffset: Config.StandardZeitEinstellung.Anleuchtung_R.Aus,
        FixOnOne: fixeinOffset,
        FixOffOne: Config.StandardZeitEinstellung.Anleuchtung_R.FixAus,
        FixOnTwo: Config.StandardZeitEinstellung.Anleuchtung_R.FixEin,
        FixOffTwo: fixeinOffset2,
        Dimmlevel: Config.StandardDimmWerte.Anleuchtung_R.Dimmlevel
      }
    })
    .catch((err) => { alert(err) })
  }

  return (
    <>
      {
        isLoading?(<Loading />):(<></>)
      }
      <Container>
        <Row>
          <Col className="mt-3">
            <Alert variant="success">Anleuchtung R</Alert>
          </Col>
        </Row>
        {
          warning ?
            (
              <>
                <h4 style={{ color: 'red' }}>!!! Achtung Beleuchtung im unerlaubten Bereich !!!</h4>
              </>
            ) : (<></>)
        }
        <Row>
          <Col className='d-grid'>
            <h5 style={{ marginBlockEnd: "0px" }}>Zeitmodus:</h5>
            <ButtonGroup aria-label="Basic example" className="mt-4">
              <Button variant={sonnen} id="Sonne" onClick={handleSonne}>
                Sonnenstand
              </Button>
              <Button variant={fix} id="Fix" onClick={handleSonne}>
                Fixe Zeiten
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <div className="Seperator mt-3"></div>
        <Row>
          <Col>
            {sonnen == "success" ? (
              <div>
                <h5>Einschaltzeit 1:</h5>
                <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Offset Sonnenuntergang: " + einOffset + "min"}</p>
                <input type="range" min="-400" max="400" step="5" value={einOffset} onChange={(e) => seteinOffset(e.target.value)} onMouseUp={postData} className='dimm' />
              </div>
            ) : (
              <div>
                <h5>Einschaltzeit 1:</h5>
                <input type="time" value={fixeinOffset} onChange={(e) => { setFixeinOffset(e.target.value);}}></input><br />
              </div>
            )}
          </Col>
          <Col>
            <div>
              <h5>Ausschaltzeit 1:</h5>
              <input type="time" value={ausOffset} onChange={(e) => { setausOffset(e.target.value);}}></input><br />
            </div>

          </Col>
          <Col>
            <div>
              <h5>Einschaltzeit 2:</h5>
              <input type="time" value={ausOffset2} onChange={(e) => { setausOffset2(e.target.value);}}></input><br />
            </div>
          </Col>
          <Col>
            {sonnen == "success" ? (
              <div>
                <h5>Ausschaltzeit 2:</h5>
                <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Offset Sonnenaufgang: " + einOffset2 + "min"}</p>
                <input type="range" min="-400" max="400" step="5" value={einOffset2} onChange={(e) => seteinOffset2(e.target.value)} onMouseUp={postData} className='dimm' />
              </div>
            ) : (
              <div>
                <h5>Ausschaltzeit 2:</h5>
                <input type="time" value={fixeinOffset2} onChange={(e) => { setFixeinOffset2(e.target.value);}}></input><br />
              </div>
            )}
          </Col>
        </Row>
        <h5>Bitte beachten die bei der Eingabe von festen Zeitpunkten, dass der Sonnenuntergang und Sonnenaufgang über das Jahr hinweg sich verändert. Sollte der Auf- oder Untergangs Zeitpunkt die fixen Zeiten überschreiten, kann dies zu unerwünschten Verhalten der Lichtanlage führen.</h5>
        <div className="Seperator mt-3"></div>
        <Row>
          <Col className='d-grid gap-2 mt-3'>
            <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Helligkeit: " + dimmVal + "%"}</p>
            <input type="range" min="0" max="100" value={dimmVal} onChange={(e) => { setDimmVal(e.target.value); }} onMouseUp={postData} className='dimm' />
          </Col>
        </Row>
        <div className="Seperator mt-3"></div>
        <Row>
          <Col className="d-grid gap-2 mt-3">
            <Button variant="danger" size="lg" onClick={ResetData}>
              Reset auf Standardwerte für Anleuchtung R
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}
