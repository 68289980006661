import React, { useState, useEffect } from 'react'
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Config from '../Config/Config.json'
import socket from '../../socket'
import axios from 'axios';
import { useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from "uuid";
import Loading from '../../main_components/spinner'

export default function Farbige_Beleuchtung() {

  const location = useLocation()
  const [UUID, setUUID] = useState(location.state.UUID)
  const [vorgang, setVorgang] = useState("solved")
  const [warning, setWarning] = useState(false)
  const [dimmVal, setDimmVal] = useState(10)
  const [einOffset, seteinOffset] = useState(0)
  const [einfix, setEinfix] = useState("00:00:00")
  const [einOffset2, seteinOffset2] = useState(0)
  const [einfix2, setEinfix2] = useState("00:00:00")
  const [einOffset3, seteinOffset3] = useState(0)
  const [einfix3, setEinfix3] = useState("00:00:00")
  const [einOffset4, seteinOffset4] = useState(0)
  const [einfix4, setEinfix4] = useState("00:00:00")
  const [sonnen, setsonnen] = useState("outline-success")
  const [fix, setfix] = useState("success")
  const [isFix, setIsFix] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [config, setConfig] = useState({
    "StandardDimmWerte": Config.StandardDimmWerte.Farbige_Beleuchtung,
    "StandardZeitEinstellung": Config.StandardZeitEinstellung.Farbige_Beleuchtung
  })

  useEffect(() => { compareValues() }, [isFix, dimmVal, einOffset, einOffset2, einOffset3, einOffset4])
  const [Counter, setCounter] = useState(0)
  useEffect(() => {var Cnt = Counter; Counter <= 1 ? setCounter(Cnt+=1) : postData()}, [einfix, einfix2, einfix3, einfix4])

  const handleSonne = (event) => {
    if (event.target.id === "Sonne") {
      setIsFix(false)
      postData2(false)
      setsonnen("success")
      setfix("outline-success")
    } else {
      setIsFix(true)
      postData2(true)
      setsonnen("outline-success")
      setfix("success")
    }
  }

  useEffect(()=>{
    socket.get_solved((data) => {
      if(vorgang === data.postID){
        if(data.isSolved){
          setIsLoading(false)
          setVorgang("solved")
          socket.close()
        }else{
          alert("Keine Verbindung zum Server!")
          setIsLoading(false)
          setVorgang("solved")
          socket.close()
        }
      }
    })
  },[vorgang])

  useEffect(() => {
    socket.get_socket_data((data) => {
      if (data.name === "RPN" && data.table === "farbige_beleuchtung") {
        setIsFix(!!data.row.IsFixedTime)
        setDimmVal(data.row.Dimmlevel)
        seteinOffset(data.row.SummerSunSetOffsetOn)
        seteinOffset2(data.row.SummerSunSetOffsetOff)
        seteinOffset3(data.row.WinterSunSetOffsetOn)
        seteinOffset4(data.row.WinterSunSetOffsetOff)
        setEinfix(data.row.FixSummerOn)
        setEinfix2(data.row.FixSummerOff)
        setEinfix3(data.row.FixWinterOn)
        setEinfix4(data.row.FixWinterOff)
        if (!!data.row.IsFixedTime) {
          setsonnen("outline-success")
          setfix("success")
        } else {
          setsonnen("success")
          setfix("outline-success")
        }
      }
    })
  }, [])

  useEffect(() => {
    axios.get(`https://${window.location.hostname}:443/getData`, {
      params: {
        UUID: UUID,
        stID: "RPN",
        areaID: "farbige_beleuchtung"
      }
    })
      .then((res) => {
        if (res.status !== 200) {
          alert(res.statusText)
          return
        }
        setIsFix(!!res.data.IsFixedTime)
        setDimmVal(res.data.Dimmlevel)
        seteinOffset(res.data.SummerSunSetOffsetOn)
        seteinOffset2(res.data.SummerSunSetOffsetOff)
        seteinOffset3(res.data.WinterSunSetOffsetOn)
        seteinOffset4(res.data.WinterSunSetOffsetOff)
        setEinfix(res.data.FixSummerOn)
        setEinfix2(res.data.FixSummerOff)
        setEinfix3(res.data.FixWinterOn)
        setEinfix4(res.data.FixWinterOff)
        if (!!res.data.IsFixedTime) {
          setsonnen("outline-success")
          setfix("success")
        } else {
          setsonnen("success")
          setfix("outline-success")
        }
      })
      .catch((err) => alert(err))
    
  }, [])

  const compareValues = () => {
    if (
      isFix
      || dimmVal !== Config.StandardDimmWerte.Farbige_Beleuchtung.Dimmlevel
      || einOffset !== Config.StandardZeitEinstellung.Farbige_Beleuchtung.Somme_Ein
      || einOffset2 !== Config.StandardZeitEinstellung.Farbige_Beleuchtung.Sommer_Aus
      || einOffset3 !== Config.StandardZeitEinstellung.Farbige_Beleuchtung.Winter_Ein
      || einOffset4 !== Config.StandardZeitEinstellung.Farbige_Beleuchtung.Winter_Aus
    ) {
      setWarning(true)
    } else {
      setWarning(false)
    }
  }

  const postData = () => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPN",
      areaID: "farbige_beleuchtung",
      UUID: UUID,
      postID: postID,
      data: {
        IsFixedTime: isFix,
        SummerSunSetOffsetOn: einOffset,
        SummerSunSetOffsetOff: einOffset2,
        WinterSunSetOffsetOn: einOffset3,
        WinterSunSetOffsetOff: einOffset4,
        FixSummerOn: einfix,
        FixSummerOff: einfix2,
        FixWinterOn: einfix3,
        FixWinterOff: einfix4,
        Dimmlevel: dimmVal
      }
    })
    .catch((err) => { alert(err) })
  }
  const postData2 = (val) => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPN",
      areaID: "farbige_beleuchtung",
      postID: postID,
      UUID: UUID,
      data: {
        IsFixedTime: val,
        SummerSunSetOffsetOn: einOffset,
        SummerSunSetOffsetOff: einOffset2,
        WinterSunSetOffsetOn: einOffset3,
        WinterSunSetOffsetOff: einOffset4,
        FixSummerOn: einfix,
        FixSummerOff: einfix2,
        FixWinterOn: einfix3,
        FixWinterOff: einfix4,
        Dimmlevel: dimmVal
      }
    })
    .catch((err) => { alert(err) })
  }
  const ResetData = () => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPN",
      areaID: "farbige_beleuchtung",
      postID: postID,
      UUID: UUID,
      data: {
        IsFixedTime: false,
        SummerSunSetOffsetOn: Config.StandardZeitEinstellung.Farbige_Beleuchtung.Somme_Ein,
        SummerSunSetOffsetOff: Config.StandardZeitEinstellung.Farbige_Beleuchtung.Sommer_Aus,
        WinterSunSetOffsetOn: Config.StandardZeitEinstellung.Farbige_Beleuchtung.Winter_Ein,
        WinterSunSetOffsetOff: Config.StandardZeitEinstellung.Farbige_Beleuchtung.Winter_Aus,
        FixSummerOn: einfix,
        FixSummerOff: einfix2,
        FixWinterOn: einfix3,
        FixWinterOff: einfix4,
        Dimmlevel: Config.StandardDimmWerte.Farbige_Beleuchtung.Dimmlevel
      }
    })
    .catch((err) => { alert(err) })
  }

  return (
    <>
      {
        isLoading?(<Loading />):(<></>)
      }
      <Container>
        <Row>
          <Col className="mt-3">
            <Alert variant="success">Farbige Beleuchtung</Alert>
          </Col>
        </Row>
        {
          warning ?
            (
              <>
                <h4 style={{ color: 'red' }}>!!! Achtung Beleuchtung im unerlaubten Bereich !!!</h4>
              </>
            ) : (<></>)
        }
        <Row>
          <Col className='d-grid'>
            <h5 style={{ marginBlockEnd: "0px" }}>Zeitmodus:</h5>
            <ButtonGroup aria-label="Basic example" className="mt-4">
              <Button variant={sonnen} id="Sonne" onClick={handleSonne}>
                Sonnenstand
              </Button>
              <Button variant={fix} id="Fix" onClick={handleSonne}>
                Fixe Zeiten
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <div className="Seperator mt-3"></div>
        <Row>
          <Col>
            {sonnen == "success" ? (
              <div>
                <h5>Einschaltzeit Sommer: </h5>
                <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Offset Sonnenuntergang: " + einOffset + "min"}</p>
                <input type="range" min="-500" max="500" step="5" value={einOffset} onChange={(e) => seteinOffset(e.target.value)} onMouseUp={postData} className='dimm' />
              </div>
            ) : (
              <div>
                <h5>Einschaltzeit Sommer: </h5>
                <input type="time" value={einfix} onChange={(e) => { setEinfix(e.target.value);}}></input><br />
              </div>
            )}

          </Col>
          <Col>
            {sonnen == "success" ? (
              <div>
                <h5>Ausschaltzeit Sommer:</h5>
                <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Offset Sonnenuntergang: " + einOffset2 + "min"}</p>
                <input type="range" min="-500" max="500" step="5" value={einOffset2} onChange={(e) => seteinOffset2(e.target.value)} onMouseUp={postData} className='dimm' />
              </div>
            ) : (
              <div>
                <h5>Ausschaltzeit Sommer:</h5>
                <input type="time" value={einfix2} onChange={(e) => { setEinfix2(e.target.value);}}></input><br />
              </div>
            )}

          </Col>
          <Col>
            {sonnen == "success" ? (
              <div>
                <h5>Einschaltzeit Winter:</h5>
                <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Offset Sonnenuntergang: " + einOffset3 + "min"}</p>
                <input type="range" min="-500" max="500" step="5" value={einOffset3} onChange={(e) => seteinOffset3(e.target.value)} onMouseUp={postData} className='dimm' />
              </div>
            ) : (
              <div>
                <h5>Einschaltzeit Winter:</h5>
                <input type="time" value={einfix3} onChange={(e) => { setEinfix3(e.target.value);}}></input><br />
              </div>
            )}

          </Col>
          <Col>
            {sonnen == "success" ? (
              <div>
                <h5>Ausschaltzeit Winter:</h5>
                <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Offset Sonnenuntergang: " + einOffset4 + "min"}</p>
                <input type="range" min="-500" max="500" step="5" value={einOffset4} onChange={(e) => seteinOffset4(e.target.value)} onMouseUp={postData} className='dimm' />
              </div>
            ) : (
              <div>
                <h5>Ausschaltzeit Winter:</h5>
                <input type="time" value={einfix4} onChange={(e) => { setEinfix4(e.target.value);}}></input><br />
              </div>
            )}
          </Col>
        </Row>
        <h5>Bitte beachten die bei der Eingabe von festen Zeitpunkten, dass der Sonnenuntergang und Sonnenaufgang über das Jahr hinweg sich verändert. Sollte der Auf- oder Untergangs Zeitpunkt die fixen Zeiten überschreiten, kann dies zu unerwünschten Verhalten der Lichtanlage führen.</h5>
        <div className="Seperator mt-3"></div>
        <Row>
          <Col className='d-grid gap-2 mt-3'>
            <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Helligkeit für alle: " + dimmVal + "%"}</p>
            <input type="range" min="0" max="100" value={dimmVal} onChange={(e) => setDimmVal(e.target.value)} onMouseUp={postData} className='dimm' />
          </Col>
        </Row>
        <div className="Seperator mt-3"></div>
        <Row>
          <Col className="d-grid gap-2 mt-3">
            <Button variant="danger" size="lg" onClick={ResetData}>
              Reset auf Standardwerte für Farbige Beleuchtung
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}
