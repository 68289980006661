import React from 'react'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoginForm4 from '../main_components/loginFormSimple'

import Logo3 from '../img/logo1.png'

export default function Login() {
  return (
    <div>
        <Container>
          <Row>
            <Col>
              <img id="logo-login" src={Logo3} alt="Logo3" />
            </Col>
          </Row>
          <h2>Revierpark Vonderort</h2>
        </Container>
        
        <LoginForm4 locationID="RPV" />
    </div>
  )
}