import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";

function CollapsibleExample(props) {
  const history = useNavigate();
  const [UUID, setUUID] = useState(props.UUID);

  const Logout = () => {
    axios.post(`https://` + window.location.hostname + `:443/logout`, { UUID: UUID, stID: "RPN" })
      .then(res => {
        if (res.status !== 200) {
          alert(res.statusText)
        }
      })
      .catch(err => console.error(err))
      .finally(() => {
        history("/login_" + props.location)
      })
  }

  return (
    <Navbar collapseOnSelect expand="lg" bg="success" variant="dark">
      <Container>
        <Navbar.Brand onClick={() => history("/RPN/Allgemein", { state: { UUID: UUID } })}>
          Nienhausen
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={() => history("/RPN/Allgemein", { state: { UUID: UUID } })}>
              Allgemein
            </Nav.Link>
            <NavDropdown title="Steuerung" id="collasible-nav-dropdown">
              <NavDropdown.Item onClick={() => history("/RPN/Anleuchtung_R", { state: { UUID: UUID } })}>
                Anleuchtung R
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => history("/RPN/Farbige_Beleuchtung", { state: { UUID: UUID } })}
              >
                Farbige Beleuchtung
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => history("/RPN/Parkplatzbeleuchtung", { state: { UUID: UUID } })}
              >
                Parkplatzbeleuchtung
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => history("/RPN/Sportplatzbeleuchtung", { state: { UUID: UUID } })}
              >
                Sportplatzbeleuchtung
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => history("/RPN/Wegebeleuchtung_weiß", { state: { UUID: UUID } })}
              >
                Wegebeleuchtung weiß
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link onClick={() => history("/RPN/Fehlerübersicht", { state: { UUID: UUID } })}>
              Fehlerübersicht
            </Nav.Link>
            <Nav.Link onClick={() => history("/RPN/User_management", { state: { UUID: UUID } })}>
              Benutzerverwaltung
            </Nav.Link>
          </Nav>
          <Nav>
            <Button variant="danger" onClick={Logout}>
              Logout
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;
