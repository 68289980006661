import React, {useState, useEffect} from 'react'
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Config from '../Config/Config.json'
import {useLocation} from 'react-router-dom'
import axios from 'axios';
import socket from '../../socket'
import { v4 as uuidv4 } from "uuid";
import Loading from '../../main_components/spinner'
  

export default function Parkplatzbereich() {

  const [isLoading, setIsLoading] = useState(false)
  const [vorgang, setVorgang] = useState("solved")
  const location = useLocation()
  const [UUID, setUUID] = useState(location.state.UUID)
  const [warning, setWarning] = useState(false)
  const [dimmVal1, setDimmVal1] = useState(10)
  const [dimmVal2, setDimmVal2] = useState(10)
  const [dimmVal3, setDimmVal3] = useState(10)
  const [einOffset, seteinOffset] = useState(0)
  const [einfix, setEinfix] = useState("00:00:00")
  const [einOffset2, seteinOffset2] = useState(0)
  const [lower, setLower] = useState("00:00:00")
  const [rise, setRise] = useState("00:00:00")
  const [einfix2, setEinfix2] = useState("00:00:00")
  const [Ein2, setEin2] = useState("outline-success")
  const [Aus2, setAus2] = useState("danger")
  const [sonnen, setsonnen] = useState("outline-success")
  const [fix, setfix] = useState("success")
  const [isFix, setIsFix] = useState(false)
  const [isRise, setIsRise] = useState(false)
  const [config, setConfig] = useState({
    "StandardDimmWerte": Config.StandardDimmWerte.Parkplatzbeleuchtung,
    "StandardZeitEinstellung": Config.StandardZeitEinstellung.Parkplatzbeleuchtung
  })

  useEffect(() => { compareValues() }, [isFix, isRise, dimmVal1, dimmVal2, dimmVal3, einOffset, einfix, einOffset2, einfix2])
  const [Counter, setCounter] = useState(0)
  useEffect(() => {var Cnt = Counter; Counter <= 1 ? setCounter(Cnt+=1) : postData()}, [einfix,
    lower,
    rise,
    einfix2])

  const handleSonne = (event) => {
    if (event.target.id === "Sonne") {
      setIsFix(false)
      postData2(false)
      setsonnen("success")
      setfix("outline-success")
    } else {
      setIsFix(true)
      postData2(true)
      setsonnen("outline-success")
      setfix("success")
    }
  }

  useEffect(()=>{
    socket.get_solved((data) => {
      if(vorgang === data.postID){
        if(data.isSolved){
          setIsLoading(false)
          setVorgang("solved")
          socket.close()
        }else{
          alert("Keine Verbindung zum Server!")
          setIsLoading(false)
          setVorgang("solved")
          socket.close()
        }
      }
    })
  },[vorgang])

  useEffect(() => {
    socket.get_socket_data((data) => {
      if (data.name === "RPV" && data.table === "parkplatzbereich") {
        setIsFix(!!data.row.IsFixedTime)
        setDimmVal1(data.row.DimmlevelToNightLower)
        setDimmVal2(data.row.DimmlevelAtNightLower)
        setDimmVal3(data.row.DimmlevelAtNightRise)
        seteinOffset(data.row.SunSetOffset)
        setEinfix(data.row.FixOnOne)
        seteinOffset2(data.row.SunRiseOffset)
        setEinfix2(data.row.FixOffTwo)
        setLower(data.row.FixOffOne)
        setRise(data.row.FixOnTwo)
        setIsRise(!!data.row.IsNightRise)
        if (!!data.row.IsFixedTime) {
          setsonnen("outline-success")
          setfix("success")
        } else {
          setsonnen("success")
          setfix("outline-success")
        }
        if (!!data.row.IsNightRise) {
          setEin2("success")
          setAus2("outline-danger")
        } else {
          setEin2("outline-success")
          setAus2("danger")
        }
      }
    })
  }, [])

  useEffect(() => {
    axios.get(`https://${window.location.hostname}:443/getData`, {
      params: {
        UUID: UUID,
        stID: "RPV",
        areaID: "parkplatzbereich"
      }
    })
      .then((res) => {
        if (res.status !== 200) {
          alert(res.statusText)
          return
        }
        setIsFix(!!res.data.IsFixedTime)
        setDimmVal1(res.data.DimmlevelToNightLower)
        setDimmVal2(res.data.DimmlevelAtNightLower)
        setDimmVal3(res.data.DimmlevelAtNightRise)
        seteinOffset(res.data.SunSetOffset)
        setEinfix(res.data.FixOnOne)
        seteinOffset2(res.data.SunRiseOffset)
        setEinfix2(res.data.FixOffTwo)
        setLower(res.data.FixOffOne)
        setRise(res.data.FixOnTwo)
        setIsRise(!!res.data.IsNightRise)
        if (!!res.data.IsFixedTime) {
          setsonnen("outline-success")
          setfix("success")
        } else {
          setsonnen("success")
          setfix("outline-success")
        }
        if (!!res.data.IsNightRise) {
          setEin2("success")
          setAus2("outline-danger")
        } else {
          setEin2("outline-success")
          setAus2("danger")
        }
      })
      .catch((err) => alert(err))
    
  }, [])

  const compareValues = () => {
    if (
      isFix
      || dimmVal1 !== Config.StandardDimmWerte.Parkplatzbeleuchtung.DimmlevelBisNachtabsenkung
      || dimmVal2 !== Config.StandardDimmWerte.Parkplatzbeleuchtung.DimmlevelNachtabsenkung
      || dimmVal3 !== Config.StandardDimmWerte.Parkplatzbeleuchtung.DimmlevelNachterhöhung
      || einOffset !== Config.StandardZeitEinstellung.Parkplatzbeleuchtung.Ein
      || isRise
      || lower !== Config.StandardZeitEinstellung.Parkplatzbeleuchtung.Nachtabsenkung + ":00"
      || einOffset2 !== Config.StandardZeitEinstellung.Parkplatzbeleuchtung.Aus
    ) {
      setWarning(true)
    } else {
      setWarning(false)
    }
  }

  const postData = () => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPV",
      areaID: "parkplatzbereich",
      postID: postID,
      UUID: UUID,
      data: {
        IsFixedTime: isFix,
        SunSetOffset: einOffset,
        SunRiseOffset: einOffset2,
        FixOnOne: einfix,
        FixOffOne: lower,
        FixOnTwo: rise,
        FixOffTwo: einfix2,
        DimmlevelToNightLower: dimmVal1,
        DimmlevelAtNightLower: dimmVal2,
        DimmlevelAtNightRise: dimmVal3,
        IsNightRise: isRise
      }
    })
    .catch((err) => { alert(err) })
  }
  const postData2 = (val) => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPV",
      areaID: "parkplatzbereich",
      postID: postID,
      UUID: UUID,
      data: {
        IsFixedTime: val,
        SunSetOffset: einOffset,
        SunRiseOffset: einOffset2,
        FixOnOne: einfix,
        FixOffOne: lower,
        FixOnTwo: rise,
        FixOffTwo: einfix2,
        DimmlevelToNightLower: dimmVal1,
        DimmlevelAtNightLower: dimmVal2,
        DimmlevelAtNightRise: dimmVal3,
        IsNightRise: isRise
      }
    })
    .catch((err) => { alert(err) })
  }
  const postData3 = (val) => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPV",
      areaID: "parkplatzbereich",
      postID: postID,
      UUID: UUID,
      data: {
        IsFixedTime: isFix,
        SunSetOffset: einOffset,
        SunRiseOffset: einOffset2,
        FixOnOne: einfix,
        FixOffOne: lower,
        FixOnTwo: rise,
        FixOffTwo: einfix2,
        DimmlevelToNightLower: dimmVal1,
        DimmlevelAtNightLower: dimmVal2,
        DimmlevelAtNightRise: dimmVal3,
        IsNightRise: val
      }
    })
    .catch((err) => { alert(err) })
  }
  const handleEinAus2 = (event) => {
    if (event.target.id === "Ein2") {
      setEin2("success")
      setIsRise(true)
      postData3(true)
      setAus2("outline-danger")
    } else {
      setEin2("outline-success")
      setIsRise(false)
      postData3(false)
      setAus2("danger")
    }
  }
  const ResetData = () => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPV",
      areaID: "parkplatzbereich",
      postID: postID,
      UUID: UUID,
      data: {
        IsFixedTime: false,
        SunSetOffset: Config.StandardZeitEinstellung.Parkplatzbeleuchtung.Ein,
        SunRiseOffset: Config.StandardZeitEinstellung.Parkplatzbeleuchtung.Aus,
        FixOnOne: einfix,
        FixOffOne: Config.StandardZeitEinstellung.Parkplatzbeleuchtung.Nachtabsenkung,
        FixOnTwo: Config.StandardZeitEinstellung.Parkplatzbeleuchtung.Nachterhöhung,
        FixOffTwo: einfix2,
        DimmlevelToNightLower: Config.StandardDimmWerte.Parkplatzbeleuchtung.DimmlevelBisNachtabsenkung,
        DimmlevelAtNightLower: Config.StandardDimmWerte.Parkplatzbeleuchtung.DimmlevelNachtabsenkung,
        DimmlevelAtNightRise: Config.StandardDimmWerte.Parkplatzbeleuchtung.DimmlevelNachterhöhung,
        IsNightRise:false
      }
    })
    .catch((err) => { alert(err) })
  }

  return (
    <>
          {
        isLoading?(<Loading />):(<></>)
      }
      <Container>
        <Row>
          <Col className="mt-3">
            <Alert variant="success">Parkplatzbeleuchtung</Alert>
          </Col>
        </Row>
        {
          warning ?
            (
              <>
                <h4 style={{ color: 'red' }}>!!! Achtung Beleuchtung im unerlaubten Bereich !!!</h4>
              </>
            ) : (<></>)
        }
        <Row>
          <Col className='d-grid'>
            <h5 style={{ marginBlockEnd: "0px" }}>Zeitmodus:</h5>
            <ButtonGroup aria-label="Basic example" className="mt-4">
              <Button variant={sonnen} id="Sonne" onClick={handleSonne}>
                Sonnenstand
              </Button>
              <Button variant={fix} id="Fix" onClick={handleSonne}>
                Fixe Zeiten
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <div className="Seperator mt-3"></div>
        <Row>
          <Col>
            {sonnen == "success" ? (
              <div>
                <h5>Einschaltzeit:</h5>
                <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Offset Sonnenuntergang: " + einOffset + "min"}</p>
                <input type="range" min="-400" max="400" step="5" value={einOffset} onChange={(e) => seteinOffset(e.target.value)} onMouseUp={postData} className='dimm' />
              </div>
            ) : (
              <div>
                <h5>Einschaltzeit:</h5>
                <input type="time" value={einfix} onChange={(e) => { setEinfix(e.target.value);}}></input><br /><br />
              </div>
            )}

          </Col>
          <Col className='d-grid'>
            <h5>Nachtabsenkung:</h5>
            <input style={{height: "30px"}} type="time" value={lower} onChange={(e) => { setLower(e.target.value);}}></input><br /><br />
          </Col>
          <Col className='d-grid'>
            <h5>Nachterhöhung:</h5>
            <input type="time" value={rise} onChange={(e) => { setRise(e.target.value);}}></input><br /><br />
            <ButtonGroup aria-label="Basic example">
              <Button variant={Ein2} id="Ein2" onClick={handleEinAus2}>
                Ein
              </Button>
              <Button variant={Aus2} id="Aus2" onClick={handleEinAus2}>
                Aus
              </Button>
            </ButtonGroup>
          </Col>
          <Col>
            {sonnen == "success" ? (
              <div>
                <h5>Ausschaltzeit:</h5>
                <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Offset Sonnenaufgang: " + einOffset2 + "min"}</p>
                <input type="range" min="-400" max="400" step="5" value={einOffset2} onChange={(e) => seteinOffset2(e.target.value)} onMouseUp={postData} className='dimm' />
              </div>
            ) : (
              <div>
                <h5>Ausschaltzeit:</h5>
                <input type="time" value={einfix2} onChange={(e) => { setEinfix2(e.target.value);}}></input><br /><br />
              </div>
            )}

          </Col>
        </Row>
        <h5>Bitte beachten die bei der Eingabe von festen Zeitpunkten, dass der Sonnenuntergang und Sonnenaufgang über das Jahr hinweg sich verändert. Sollte der Auf- oder Untergangs Zeitpunkt die fixen Zeiten überschreiten, kann dies zu unerwünschten Verhalten der Lichtanlage führen.</h5>
        <div className="Seperator mt-3"></div>
        <Row>
          <Col className='d-grid gap-2 mt-3'>
            <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Helligkeit bis Nachtabsenkung: " + dimmVal1 + "%"}</p>
            <input type="range" min="0" max="100" value={dimmVal1} onChange={(e) => setDimmVal1(e.target.value)} onMouseUp={postData} className='dimm' />
          </Col>
        </Row>
        <Row>
          <Col className='d-grid gap-2 mt-3'>
            <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Helligkeit Nachtabsenkung: " + dimmVal2 + "%"}</p>
            <input type="range" min="0" max="100" value={dimmVal2} onChange={(e) => setDimmVal2(e.target.value)} onMouseUp={postData} className='dimm' />
          </Col>
        </Row>
        <Row>
          <Col className='d-grid gap-2 mt-3'>
            <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Helligkeit Nachterhöhung: " + dimmVal3 + "%"}</p>
            <input type="range" min="0" max="100" value={dimmVal3} onChange={(e) => setDimmVal3(e.target.value)} onMouseUp={postData} className='dimm' />
          </Col>
        </Row>
        <div className="Seperator mt-3"></div>
        <Row>
          <Col className="d-grid gap-2 mt-3">
            <Button variant="danger" size="lg" onClick={ResetData}>
              Reset auf Standardwerte für Parkplatzbeleuchtung
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}
