import React, {useState} from 'react'
import Navbar from './Components/Navbar'
import { Outlet } from 'react-router-dom'
import {useLocation} from 'react-router-dom'

export default function RPN() {
  const location = useLocation()
  const [UUID, setUUID] = useState(location.state.UUID)

  return (
    <div>
      <Navbar location="RPN" UUID={UUID} />
      <Outlet />
    </div>
  )
}
