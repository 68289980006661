import Table from "react-bootstrap/Table";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import socket from '../../socket'
import Loading from '../../main_components/spinner'

function DarkExample(props) {
  const [UUID, setUUID] = useState(props.UUID);
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [vorgang, setVorgang] = useState("solved");

  useEffect(() => {
    setContent(props.content);
  }, [props.content]);

  const Quittieren = (event) => {
    const id = event.target.id;
    const postID = uuidv4();
    setIsLoading(true);
    setVorgang(postID);
    content.forEach((q) => {
      if (q.Uuid === id) {
        axios
          .post(`https://${window.location.hostname}:443/quittError`, {
            stID: "RPG",
            postID: postID,
            UUID: UUID,
            data: {
              q,
            },
          })
          .then((res)=>{
            if(res.status === 200){
                if(res.data){
                    setIsLoading(false)
                    setVorgang("solved")
                }
            }
          })
          .catch((err) => {
            alert(err);
          });
      }
    });
  };

  useEffect(()=>{
    socket.get_solved((data) => {
      if(vorgang === data.postID){
        if(data.isSolved){
          setIsLoading(false)
          setVorgang("solved")
          socket.close()
        }else{
          alert("Keine Verbindung zum Server!")
          setIsLoading(false)
          setVorgang("solved")
          socket.close()
        }
      }
    })
  },[vorgang])

  return (
    <>
      {isLoading ? <Loading /> : <></>}
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>Gruppe:</th>
            <th>Gerät:</th>
            <th>Meldung:</th>
            <th>Aufgetreten am:</th>
            <th>Quittieren</th>
          </tr>
        </thead>
        <tbody>
          {content.map((content, idx) => (
            <tr id={"T-"+content.Uuid}>
              <td>{content.Group}</td>
              <td>{content.Source}</td>
              <td>{content.Type}</td>
              <td>{new Date(content.Occurrence).toLocaleString("de-DE")}</td>
              <td>
                <Button id={content.Uuid} variant="danger" onClick={Quittieren}>
                  Quittieren
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

export default DarkExample;
