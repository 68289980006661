import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import socket from "../../socket";
import { ReactComponent as MySVG } from "../../svg/RPG.svg";
import { useNavigate } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import x from "../../svg/x.svg";
import Tabelle from "./table";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Container from "react-bootstrap/esm/Container";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../main_components/spinner";

export default function Svg(props) {
  const [activeGroup, setActiveGroup] = useState("NULL");
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const svgRef = useRef(null);

  const [UUID, setUUID] = useState(props.UUID);
  const [cnt, setcnt] = useState(0);
  const [msg, setMsg] = useState([]);
  const [isPopUp, setIsPopUp] = useState(false);
  const [AktiveMSGList, setAktiveMSGList] = useState([]);
  const [GroupList, setGroupList] = useState([
    "G-1",
    "G-2",
    "G-4_3",
    "G-4_3",
    "G-6_5",
    "G-6_5",
    "G-8_7",
    "G-8_7",
    "G-10_9",
    "G-10_9",
    "G-12_11",
    "G-12_11",
    "G-13",
    "G-14",
    "G-15",
    "G-17_16",
    "G-17_16",
    "G-18",
    "G-19",
    "G-20",
    "G-21",
    "G-22",
    "G-23",
    "G-24",
    "G-25",
    "G-26",
    "G-27_28",
    "G-27_28",
    "G-29",
  ]);

  const handleClick = (event) => {
    var zwtable = [];
    const clickedElement = event.target;
    const elementId = clickedElement.id;
    var num = null;
    msg.forEach((m) => {
      if (GroupList[parseInt(m.Group) - 1] === elementId) {
         num = m.Group;
        zwtable.push(m);
      }
    });
    setAktiveMSGList(zwtable);
    setActiveGroup(num);
    setIsPopUp(true);
  }

  useEffect(() => {
    if (cnt !== 0) {
      var elems = [];

      if (msg.length > 0) {
        msg.forEach((m) => {
          if (
            parseInt(m.Group) >= 0 &&
            parseInt(m.Group) <= GroupList.length + 1
          ) {
            var G = svgRef.current.querySelector(
              "#" + GroupList[parseInt(m.Group) - 1]
            );
            G.classList.add("element");
            G.addEventListener("click", handleClick);
            elems.push(G);
          } else {
          }
        });
        var List = [];
        for (let i in GroupList) {
          msg.forEach((m) => {
            if (parseInt(i) === parseInt(m.Group) - 1) {
              List.push(GroupList[i]);
            }
          });
        }
        for (let i in GroupList) {
          var isOtherAktive = false;
          for (let a in List) {
            if (List[a] === GroupList[i]) {
              isOtherAktive = true;
            }
          }

          if (!isOtherAktive) {
            const element = document.getElementById(GroupList[i]);
            element.classList.remove("element");
            element.removeEventListener("click", handleClick);
          }
        }
      } else {
        GroupList.forEach((id) => {
          const elements = document.getElementById(id);
          elements.classList.remove("element");
          elements.removeEventListener("click", handleClick);
        });
      }

      return () => {
        elems.forEach((m) => {
          m.removeEventListener("click", handleClick);    
      });
      };
    } else {
      setcnt(1);
    }
  }, [msg, handleClick]);

  useEffect(() => {
    GetMsg();
  }, []);

  const GetMsg = (secVal = false) => {
    axios
      .get(`https://${window.location.hostname}:443/getMSG`, {
        params: {
          UUID: UUID,
          stID: "RPG",
        },
      })
      .then((res) => {
        if (res.status !== 200) {
          alert(res.statusText);
          return;
        }
        if (res.data === "Zugriff verweigert!") {
          return;
        }
        if ("message" in res.data) {
          setMsg([]);
          setAktiveMSGList([]);
          return;
        }

        if(secVal){
          if(activeGroup === "NULL" || activeGroup === null){
            return
          }
          var zwtable = [];
          res.data.forEach((m) => {
            if (m.Group === activeGroup) {
              zwtable.push(m);
            }
          });
          setAktiveMSGList(zwtable);
          setMsg(res.data);
        }else{
          setMsg(res.data);
        }
      })
      .catch((err) => alert(err));
  };

  useEffect(() => {
    socket.get_socket_data((data) => {
      if (data.name === "RPG" && data.table === "messages") {
        GetMsg(true);
      }
    });
    return () => {
      socket.close();
    }
  }, [activeGroup]);

  useEffect(() => {
    socket.get_messages((res) => {
      if (res.data.Park === "RPG") {
        GetMsg();
      }
    });
    return () => {
      socket.close();
    }
  }, []);

  const handlDownload = async () => {
    try {
      const response = await axios.get("/download/log", {
        params: {
          stID: "RPG",
        },
      });
      const blob = new Blob([response.data], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "database_records.txt";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const QuittAll = () => {
    if (activeGroup === null) {
      return;
    }
    setIsLoading(true);
    const postID = uuidv4();
    axios
      .post(`https://${window.location.hostname}:443/quittAllError`, {
        stID: "RPG",
        postID: postID,
        UUID: UUID,
        Group: activeGroup,
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            setIsPopUp(false);
            setIsLoading(false);
            setActiveGroup(null);
            setAktiveMSGList([]);
            GetMsg();
          } else {
            setIsPopUp(false);
            setIsLoading(false);
            setActiveGroup(null);
            GetMsg();
            alert("Beim Quittieren ist ein Fehler aufgetreten!");
          }
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <>
      {isPopUp ? (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              position: "absolute",
              zIndex: "0",
              top: "0",
              left: "0",
              backgroundColor: "rgba(255,255,255,0.7)",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "10%",
              left: "20%",
              width: "60%",
              height: "80%",
              zIndex: "1",
              borderRadius: "7.5px",
              borderStyle: "solid",
              borderWidth: "2px",
              borderColor: "rgb(25, 135, 84)",
              boxShadow: "inset 4px 4px 12px 0px rgba(0,0,0,0.8)",
              backgroundColor: "rgb(25, 135, 84)",
            }}
          >
            {isLoading ? <Loading /> : <></>}
            <div
              style={{
                float: "right",
                backgroundColor: "rgb(25, 135, 84)",
                boxShadow: "inset 4px 4px 12px 0px rgba(0,0,0,0.8)",
                width: "100%",
                height: "6%",
              }}
            >
            <Button
              variant="danger"
              style={{
                float: "left",
                height: "80%",
                marginLeft: "5px",
                marginTop: "5px",
              }}
              onClick={QuittAll}
            >
              Alles Quittieren
            </Button>
              <img
                src={x}
                style={{
                  float: "right",
                  height: "80%",
                  marginTop: "0.25%",
                  width: "5%",
                }}
                className="hoverT"
                onClick={() => {
                  setIsPopUp(false);
                  setActiveGroup(null);
                }}
              ></img>
            </div>
            <div
              style={{
                backgroundColor: "white",
                zIndex: "2",
                overflowY: "auto",
                width: "100%",
                height: "95%",
                boxShadow: "inset 0px -7px 15px 0px #395E40",
              }}
            >
              <Tabelle content={AktiveMSGList} UUID={UUID} />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <TransformWrapper
        initialScale={0.6}
        minScale={0.5}
        centerOnInit={true}
        maxScale={8}
        disablePadding={true}
      >
        <TransformComponent>
          <MySVG ref={svgRef} />
        </TransformComponent>
      </TransformWrapper>
      <Container
        style={{
          width: "100%",
          float: "right",
          width: "10%",
          height: "100vh",
          borderLeftStyle: "solid",
          borderLeftWidth: "2px",
          borderLeftColor: "darkgray",
        }}
      >
        <Row>
          <Col className="d-grid gap-2 mt-3">
            <Button onClick={handlDownload} variant="dark">
              Download: log file
            </Button>
          </Col>
        </Row>
        <div className="Seperator mt-3"></div>
      </Container>
    </>
  );
}
