import React, {useState, useEffect} from 'react'
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Config from '../Config/Config.json'
import {useLocation} from 'react-router-dom'
import axios from 'axios';
import socket from '../../socket'
import { v4 as uuidv4 } from "uuid";
import Loading from '../../main_components/spinner'
  

export default function Sportplatzbeleuchtung() {
  
  const [isLoading, setIsLoading] = useState(false)
  const [vorgang, setVorgang] = useState("solved")
  const location = useLocation()
  const [UUID, setUUID] = useState(location.state.UUID)
  const [config, setConfig] = useState({
    "StandardDimmWerte": Config.StandardDimmWerte.Sportplatzbeleuchtung,
    "StandardZeitEinstellung": Config.StandardZeitEinstellung.Sportplatzbeleuchtung
  })
  const [warning, setWarning] = useState(false)
  const [dimmVal, setDimmVal] = useState(10)
  const [einOffset, seteinOffset] = useState(0)
  const [einfix, setEinFix] = useState("00:00:00")
  const [offfix, setOfffix] = useState("00:00:00")
  const [timeActive, settimeActive] = useState(0)
  const [Ein2, setEin2] = useState("outline-success")
  const [Aus2, setAus2] = useState("danger")
  const [sonnen, setsonnen] = useState("outline-success")
  const [fix, setfix] = useState("success")
  const [isFix, setIsFix] = useState(false)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => { compareValues() }, [isFix, isActive, dimmVal, einOffset, einfix, timeActive, offfix])
  const [Counter, setCounter] = useState(0)
  useEffect(() => {var Cnt = Counter; Counter <= 1 ? setCounter(Cnt+=1) : postData()}, [einfix, offfix])

  useEffect(()=>{
    socket.get_solved((data) => {
      if(vorgang === data.postID){
        if(data.isSolved){
          setIsLoading(false)
          setVorgang("solved")
          socket.close()
        }else{
          alert("Keine Verbindung zum Server!")
          setIsLoading(false)
          setVorgang("solved")
          socket.close()
        }
      }
    })
  },[vorgang])

  useEffect(() => {
    socket.get_socket_data((data) => {
      if (data.name === "RPN" && data.table === "sportplatzbeleuchtung") {
        setIsFix(!!data.row.IsFixedTime)
        setDimmVal(data.row.Dimmlevel)
        seteinOffset(data.row.SunSetOffset)
        setEinFix(data.row.FixOnOne)
        setOfffix(data.row.FixOffOne)
        settimeActive(data.row.ActiveDuration)
        setIsActive(data.row.isActive)
        if (!!data.row.IsFixedTime) {
          setsonnen("outline-success")
          setfix("success")
        } else {
          setsonnen("success")
          setfix("outline-success")
        }
        if (!!data.row.isActive) {
          setEin2("success")
          setAus2("outline-danger")
        } else {
          setEin2("outline-success")
          setAus2("danger")
        }
      }
    })
  }, [])

  useEffect(() => {
    axios.get(`https://${window.location.hostname}:443/getData`, {
      params: {
        UUID: UUID,
        stID: "RPN",
        areaID: "sportplatzbeleuchtung"
      }
    })
      .then((res) => {
        if (res.status !== 200) {
          alert(res.statusText)
          return
        }
        setIsFix(!!res.data.IsFixedTime)
        setDimmVal(res.data.Dimmlevel)
        seteinOffset(res.data.SunSetOffset)
        setEinFix(res.data.FixOnOne)
        setOfffix(res.data.FixOffOne)
        settimeActive(res.data.ActiveDuration)
        setIsActive(res.data.isActive)
        if (!!res.data.IsFixedTime) {
          setsonnen("outline-success")
          setfix("success")
        } else {
          setsonnen("success")
          setfix("outline-success")
        }
        if (!!res.data.isActive) {
          setEin2("success")
          setAus2("outline-danger")
        } else {
          setEin2("outline-success")
          setAus2("danger")
        }
      })
      .catch((err) => alert(err))
    
  }, [])

  const compareValues = () => {
    if (
      isFix
      || dimmVal !== Config.StandardDimmWerte.Sportplatzbeleuchtung.Dimmlevel
      || einOffset !== Config.StandardZeitEinstellung.Sportplatzbeleuchtung.Ein
      || offfix !== Config.StandardZeitEinstellung.Sportplatzbeleuchtung.Aus + ":00"
      || !isActive
      || timeActive !== Config.StandardZeitEinstellung.Sportplatzbeleuchtung.Aktivierung
    ) {
      setWarning(true)
    } else {
      setWarning(false)
    }
  }

  const postData = () => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPN",
      areaID: "sportplatzbeleuchtung",
      UUID: UUID,
      postID: postID,
      data: {
        IsFixedTime: isFix,
        Dimmlevel: dimmVal,
        SunSetOffset: einOffset,
        FixOnOne: einfix,
        FixOffOne: offfix,
        ActiveDuration: timeActive,
        isActive: isActive,
      }
    })
    .catch((err) => { alert(err) })
  }
  const postData2 = (val) => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPN",
      areaID: "sportplatzbeleuchtung",
      UUID: UUID,
      postID: postID,
      data: {
        IsFixedTime: val,
        Dimmlevel: dimmVal,
        SunSetOffset: einOffset,
        FixOnOne: einfix,
        FixOffOne: offfix,
        ActiveDuration: timeActive,
        isActive: isActive,
      }
    })
    .catch((err) => { alert(err) })
  }
  const postData3 = (val) => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPN",
      areaID: "sportplatzbeleuchtung",
      postID: postID,
      UUID: UUID,
      data: {
        IsFixedTime: isFix,
        Dimmlevel: dimmVal,
        SunSetOffset: einOffset,
        FixOnOne: einfix,
        FixOffOne: offfix,
        ActiveDuration: timeActive,
        isActive: val,
      }
    })
    .catch((err) => { alert(err) })
  }
  const ResetData = () => {
    const postID = uuidv4();
    setIsLoading(true)
    setVorgang(postID)
    axios.post(`https://${window.location.hostname}:443/postData`, {
      stID: "RPN",
      areaID: "sportplatzbeleuchtung",
      postID: postID,
      UUID: UUID,
      data: {
        IsFixedTime: false,
        Dimmlevel: Config.StandardDimmWerte.Sportplatzbeleuchtung.Dimmlevel,
        SunSetOffset: Config.StandardZeitEinstellung.Sportplatzbeleuchtung.Ein,
        FixOnOne: einfix,
        FixOffOne: Config.StandardZeitEinstellung.Sportplatzbeleuchtung.Aus,
        ActiveDuration: Config.StandardZeitEinstellung.Sportplatzbeleuchtung.Aktivierung,
        isActive: true,
      }
    })
    .catch((err) => { alert(err) })
  }

  const handleSonne = (event) => {
    if (event.target.id === "Sonne") {
      setsonnen("success")
      setIsFix(false)
      postData2(false)
      setfix("outline-success")
    } else {
      setsonnen("outline-success")
      setIsFix(true)
      postData2(true)
      setfix("success")
    }
  }
  const handleEinAus2 = (event) => {
    if (event.target.id === "Ein2") {
      setEin2("success")
      setIsActive(true)
      postData3(true)
      setAus2("outline-danger")
    } else {
      setEin2("outline-success")
      setIsActive(false)
      postData3(false)
      setAus2("danger")
    }
  }

  return (
    <>
          {
        isLoading?(<Loading />):(<></>)
      }
      <Container>
        <Row>
          <Col className="mt-3">
            <Alert variant="success">Sportplatzbeleuchtung</Alert>
          </Col>
        </Row>
        {
          warning ?
            (
              <>
                <h4 style={{ color: 'red' }}>!!! Achtung Beleuchtung im unerlaubten Bereich !!!</h4>
              </>
            ) : (<></>)
        }
        <Row>
          <Col className='d-grid'>
            <h5 style={{ marginBlockEnd: "0px" }}>Zeitmodus:</h5>
            <ButtonGroup aria-label="Basic example" className="mt-4">
              <Button variant={sonnen} id="Sonne" onClick={handleSonne}>
                Sonnenstand
              </Button>
              <Button variant={fix} id="Fix" onClick={handleSonne}>
                Fixe Zeiten
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <div className="Seperator mt-3"></div>
        <Row>
          <Col className='d-grid'>
            {sonnen == "success" ? (
              <div>
                <h5>Einschaltzeit:</h5>
                <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Offset Sonnenuntergang: " + einOffset + "min"}</p>
                <input type="range" min="-400" max="400" step="5" value={einOffset} onChange={(e) => seteinOffset(e.target.value)} onMouseUp={postData} className='dimm' />
              </div>
            ) : (
              <div>
                <h5>Einschaltzeit:</h5>
                <input type="time" value={einfix} onChange={(e) => { setEinFix(e.target.value);}}></input><br />
              </div>
            )}
            {/*<ButtonGroup aria-label="Basic example" className="mt-4">
              <Button variant={Ein2} id="Ein2" onClick={handleEinAus2}>
                Ein
              </Button>
              <Button variant={Aus2} id="Aus2" onClick={handleEinAus2}>
                Aus
              </Button>
            </ButtonGroup>*/}
          </Col>
          <Col>
            <h5>Ausschaltzeit:</h5>
            <input className="mt-4" style={{ width: '100%' }} type="time" value={offfix} onChange={(e) => { setOfffix(e.target.value);}}></input>
          </Col>
          <Col>
            <h5>Ausschaltzeit nach Aktivierung:</h5>
            <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Aktiv: " + timeActive + "min"}</p>
            <input type="range" min="-0" max="200" step="5" value={timeActive} onChange={(e) => settimeActive(e.target.value)} onMouseUp={postData} className='dimm' />
          </Col>
        </Row>
        <h5>Bitte beachten die bei der Eingabe von festen Zeitpunkten, dass der Sonnenuntergang und Sonnenaufgang über das Jahr hinweg sich verändert. Sollte der Auf- oder Untergangs Zeitpunkt die fixen Zeiten überschreiten, kann dies zu unerwünschten Verhalten der Lichtanlage führen.</h5>
        <div className="Seperator mt-3"></div>
        <Row>
          <Col className='d-grid gap-2 mt-3'>
            <p style={{ float: 'left', textAlign: "left", marginBlockEnd: "0px" }}>{"Helligkeit: " + dimmVal + "%"}</p>
            <input type="range" min="0" max="100" value={dimmVal} onChange={(e) => setDimmVal(e.target.value)} onMouseUp={postData} className='dimm' />
          </Col>
        </Row>
        <div className="Seperator mt-3"></div>
        <Row>
          <Col className="d-grid gap-2 mt-3">
            <Button variant="danger" size="lg" onClick={ResetData}>
              Reset auf Standardwerte für Sportplatzbeleuchtung
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}
