import Spinner from 'react-bootstrap/Spinner';

function LoadingSpinner() {
  return (
    <div
    style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        zIndex: "1",
        position: "fixed",
        top: "0",
        left: "0",
        backgroundColor: "rgba(255, 255, 255, 0.8)"
    }}
    >
        <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
        </Spinner>
    </div>
  );
}

export default LoadingSpinner;