import React, {useState} from 'react'
import {useLocation} from 'react-router-dom'
import Backgound from '../Components/svg'

export default function Fehlerübersicht() {

  const location = useLocation()
  const [UUID, setUUID] = useState(location.state.UUID)

  return (
    <>
      <Backgound UUID={UUID} />
    </>
  )
}
