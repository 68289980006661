import React from 'react'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoginForm3 from '../main_components/loginFormSimple'

import Logo1 from '../img/logo1.png'

export default function Login() {
  return (
    <div>
        <Container>
          <Row>
            <Col>
              <img id="logo-login" src={Logo1} alt="Logo1" />
            </Col>
          </Row>
          <h2>Revierpark Nienhausen</h2>
        </Container>
        
        <LoginForm3 locationID="RPN" />
    </div>
  )
}