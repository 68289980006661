import React, { useEffect, useState } from 'react'
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Modal from "react-bootstrap/Modal";
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import CryptoJS from "crypto-js";

export default function Benutzerverwaltung() {

  const [SALT, setSALT] = useState("");

  const location = useLocation()
  const [UUID, setUUID] = useState(location.state.UUID)

  const [WarningShow, setWarningShow] = useState(false)
  const [WarningTitle, setWarningTitle] = useState("")
  const [WarningText, setWarningText] = useState("")

  const [newSelfMail, setNewSelfMail] = useState("")
  const [newRepSelfMail, setNewRepSelfMail] = useState("")
  const [newSelfMailPW, setNewSelfMailPW] = useState("")

  const [newSelfPW, setNewSelfPW] = useState("")
  const [newRepSelfPW, setNewRepSelfPW] = useState("")
  const [newSelfPwPW, setNewSelfPwPW] = useState("")

  const [delUserList, setDelUserList] = useState([])
  const [delUserSelect, setDelUserSelect] = useState(null)
  const [delUserAdmin1, setDelUserAdmin1] = useState("")
  const [delUserAdmin2, setDelUserAdmin2] = useState("")

  const [newMail, setNewMail] = useState("")
  const [newRepMail, setNewRepMail] = useState("")
  const [newPW, setNewPW] = useState("")
  const [newRepPW, setNewRepPW] = useState("")
  const [newUserAdmin1, setNewUserAdmin1] = useState("")
  const [newUserAdmin2, setNewUserAdmin2] = useState("")

  useEffect(() => {
    axios.get(`https://` + window.location.hostname + `:443/getSalt`)
    .then((res)=>{
        if("Salt" in res.data){
            setSALT(res.data.Salt)
        }
    })
    axios.get(`https://` + window.location.hostname + `:443/getUser`, {
      params:{
        UUID:UUID,
        stID: "RPN"
      }
    })
    .then((res)=>{
      if(res.status !== 200){
        alert(res.statusText)
        return
      }
      if(res.data.length){
        
        setDelUserList(res.data)
      }
    })
    .catch((err) => alert(err))
  },[])

  function isEmailValid(email){
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  }
  function isPasswordValid(password){
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z\p{P}]{8,}$/u;
    return passwordRegex.test(password);
  }

  const CloseWarning = () => {
    setWarningShow(false)
    setWarningTitle("")
    setWarningText("")
  }

  const submitNewSelfMail = () => {
    if (newSelfMail !== newRepSelfMail) {
      setWarningTitle("Warnung!")
      setWarningText("Die E-Mail Adressen stimmen nicht überein!")
      setWarningShow(true)
      return
    }
    if (!isEmailValid(newSelfMail) || !isEmailValid(newRepSelfMail)) {
      setWarningTitle("Warnung!")
      setWarningText("Die E-Mail Adressen sind keine gültige E-Mail Adressen!")
      setWarningShow(true)
      return
    }
    var Passwort = null
    if (newSelfMailPW.length <= 7) {
      setWarningTitle("Warnung!")
      setWarningText("Bitte geben sie ihr Passwort ein!")
      setWarningShow(true)
      return
    }else{
      var algo = CryptoJS.algo.SHA256.create();
      algo.update(newSelfMailPW, 'utf-8');
      algo.update(CryptoJS.SHA256(SALT), 'utf-8');
      Passwort = algo.finalize().toString(CryptoJS.enc.Base64);
    }
    axios.post(`https://` + window.location.hostname + `:443/changeUser`, {
      password: Passwort,
      new:newRepSelfMail,
      stID: "RPN",
      UUID: UUID,
    })
      .then((res) => {
        if (res.status !== 200) {
          alert(res.statusText)
          return
        }
        if(res.data !== true){
          if ("message" in res.data) {
            alert(res.data.message);
            return;
          }
        }
        if (res.data) {
          setWarningTitle("Erfolgreich")
          setWarningText("Ihre E-Mail Adresse wurde erfolgreich geändert.")
          setWarningShow(true)
          setNewSelfMail("")
          setNewRepSelfMail("")
          setNewSelfMailPW("")
        }
      })
  }

  const submitNewSelfPW = () => {
    if(newSelfPW !== newRepSelfPW){
      setWarningTitle("Warnung!")
      setWarningText("Die Passwörter stimmen nicht überein!")
      setWarningShow(true)
      return
    }
    if(!isPasswordValid(newSelfPW) || !isPasswordValid(newRepSelfPW)){
      setWarningTitle("Warnung!")
      setWarningText("Die Passwörter erfüllen nicht die Mindestanforderungen. Passwörter müssen mindestens 8 Zeichen Lang sein, Zahlen und Zeichen von A bis Z enthalten und sowohl Groß als auch Klein geschriebene Zeichen von A bis Z enthalten!")
      setWarningShow(true)
      return
    }
    if(newSelfPwPW.length <= 7){
      setWarningTitle("Warnung!")
      setWarningText("Bitte geben sie ihr Passwort ein!")
      setWarningShow(true)
      return
    }else{
      var Passwort = null
      var New = null
      var algo = CryptoJS.algo.SHA256.create();
      algo.update(newSelfPwPW, 'utf-8');
      algo.update(CryptoJS.SHA256(SALT), 'utf-8');
      Passwort = algo.finalize().toString(CryptoJS.enc.Base64);
      var algo2 = CryptoJS.algo.SHA256.create();
      algo2.update(newRepSelfPW, 'utf-8');
      algo2.update(CryptoJS.SHA256(SALT), 'utf-8');
      New = algo2.finalize().toString(CryptoJS.enc.Base64);
      
      axios.post(`https://` + window.location.hostname + `:443/changeUserPassword`, {
        password: Passwort,
        new: New,
        stID: "RPN",
        UUID: UUID,
      })
        .then((res) => {
          if (res.status !== 200) {
            alert(res.statusText)
            return
          }
          if(res.data !== true){
            if ("message" in res.data) {
              alert(res.data.message);
              return;
            }
          }
          if (res.data) {
            setNewSelfPW("")
            setNewRepSelfPW("")
            setNewSelfPwPW("")
            setWarningTitle("Erfolgreich")
            setWarningText("Ihr Passwort wurde erfolgreich geändert.")
            setWarningShow(true)
          }
        })
    }
  }

  const submitDelUser = () => {
    if(delUserSelect === null){
      setWarningTitle("Warnung!")
      setWarningText("Bitte wählen sie einen Benutzer aus!")
      setWarningShow(true)
      return
    }
    if(delUserAdmin1 === ""){
      setWarningTitle("Warnung!")
      setWarningText("Bitte geben sie das Passwort für Admin 1 an!")
      setWarningShow(true)
      return
    }
    if(delUserAdmin2 === ""){
      setWarningTitle("Warnung!")
      setWarningText("Bitte geben sie das Passwort für Admin 2 an!")
      setWarningShow(true)
      return
    }


    var pwad1 = null
    var pwad2 = null
    var algo = CryptoJS.algo.SHA256.create();
    algo.update(delUserAdmin1, 'utf-8');
    algo.update(CryptoJS.SHA256(SALT), 'utf-8');
    pwad1 = algo.finalize().toString(CryptoJS.enc.Base64);
    var algo2 = CryptoJS.algo.SHA256.create();
    algo2.update(delUserAdmin2, 'utf-8');
    algo2.update(CryptoJS.SHA256(SALT), 'utf-8');
    pwad2 = algo2.finalize().toString(CryptoJS.enc.Base64);

    axios.post(`https://` + window.location.hostname + `:443/delUser`, {
        admin1: pwad1,
        admin2: pwad2,
        user: delUserSelect,
        stID: "RPN",
        UUID: UUID,
    })
      .then((res)=>{
        if (res.status !== 200) {
          alert(res.statusText)
          return
        }
        if(res.data !== true){
          if ("message" in res.data) {
            alert(res.data.message);
            return;
          }
        }
        if(res.data) {
          setDelUserSelect(null)
          setDelUserAdmin1("")
          setDelUserAdmin2("")
          setWarningTitle("Erfolgreich")
          setWarningText("Der Benutzer "+delUserSelect+" wurde erfolgreich gelöscht.")
          setWarningShow(true)
        }
      })
  }
  const submitCreateUser = () => {
    if (newMail !== newRepMail) {
      setWarningTitle("Warnung!")
      setWarningText("Die E-Mail Adressen stimmen nicht überein!")
      setWarningShow(true)
      return
    }
    if (!isEmailValid(newMail) || !isEmailValid(newRepMail)) {
      setWarningTitle("Warnung!")
      setWarningText("Die E-Mail Adressen sind keine gültige E-Mail Adressen!")
      setWarningShow(true)
      return
    }
    if(newPW !== newRepPW){
      setWarningTitle("Warnung!")
      setWarningText("Die Passwörter stimmen nicht überein!")
      setWarningShow(true)
      return
    }
    if(!isPasswordValid(newPW) || !isPasswordValid(newRepPW)){
      setWarningTitle("Warnung!")
      setWarningText("Die Passwörter erfüllen nicht die Mindestanforderungen. Passwörter müssen mindestens 8 Zeichen Lang sein, Zahlen und Zeichen von A bis Z enthalten und sowohl Groß als auch Klein geschriebene Zeichen von A bis Z enthalten!")
      setWarningShow(true)
      return
    }
    if(newUserAdmin1 === ""){
      setWarningTitle("Warnung!")
      setWarningText("Bitte geben sie das Passwort für Admin 1 an!")
      setWarningShow(true)
      return
    }
    if(newUserAdmin2 === ""){
      setWarningTitle("Warnung!")
      setWarningText("Bitte geben sie das Passwort für Admin 2 an!")
      setWarningShow(true)
      return
    }
    var newPWhash = null
    var pwad1 = null
    var pwad2 = null
    var algo = CryptoJS.algo.SHA256.create();
    algo.update(newUserAdmin1, 'utf-8');
    algo.update(CryptoJS.SHA256(SALT), 'utf-8');
    pwad1 = algo.finalize().toString(CryptoJS.enc.Base64);
    var algo2 = CryptoJS.algo.SHA256.create();
    algo2.update(newUserAdmin2, 'utf-8');
    algo2.update(CryptoJS.SHA256(SALT), 'utf-8');
    pwad2 = algo2.finalize().toString(CryptoJS.enc.Base64);

    var algo2 = CryptoJS.algo.SHA256.create();
    algo2.update(newPW, 'utf-8');
    algo2.update(CryptoJS.SHA256(SALT), 'utf-8');
    newPWhash = algo2.finalize().toString(CryptoJS.enc.Base64);

    axios.post(`https://` + window.location.hostname + `:443/createUser`, {
      admin1: pwad1,
      admin2: pwad2,
      user: newMail,
      pw: newPWhash,
      stID: "RPN",
      UUID: UUID,
    })
      .then((res)=>{
        if (res.status !== 200) {
          alert(res.statusText)
          return
        }
        if(res.data !== true){
          if ("message" in res.data) {
            alert(res.data.message);
            return;
          }
        }
        if(res.data) {
          setNewMail("")
          setNewRepMail("")
          setNewPW("")
          setNewRepPW("")
          setNewUserAdmin1("")
          setNewUserAdmin2("")
          setWarningTitle("Erfolgreich")
          setWarningText("Der Benutzer "+newMail+" wurde erfolgreich erstellt.")
          setWarningShow(true)
        }
      })
  }

  return (
    <>
      <>
        <Modal show={WarningShow} onHide={CloseWarning}>
          <Modal.Header closeButton>
            <Modal.Title>{WarningTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{WarningText}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={CloseWarning}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      <Container>
        <Row>
          <Col className="mt-3">
            <Alert variant='success'>Benutzerverwaltung</Alert>
          </Col>
        </Row>
        <Row>
          <Col className="mt-1">
            <Alert variant='success'>Eigene Email bearbeiten:</Alert>
            <Form>
              <Form.Group className="mb-3" >
                <Form.Label>Neue Email Adresse</Form.Label>
                <Form.Control type="email" placeholder="Enter email" value={newSelfMail} onChange={(e) => setNewSelfMail(e.target.value)} />
              </Form.Group>
              <Form.Group className="mb-3" >
                <Form.Label>Email Adresse wiederholen</Form.Label>
                <Form.Control type="email" placeholder="Enter email" value={newRepSelfMail} onChange={(e) => setNewRepSelfMail(e.target.value)} />
              </Form.Group>
              <div className="Seperator mb-2"></div>
              <Form.Group className="mb-3" >
                <Form.Label>Passwort</Form.Label>
                <Form.Control type="password" placeholder="Password" value={newSelfMailPW} onChange={(e)=> setNewSelfMailPW(e.target.value)} />
              </Form.Group>
              <Button variant="primary" onClick={submitNewSelfMail}>
                Änderung speichern
              </Button>
            </Form>
            <Alert className="mt-2" variant='success'>Eigens Passwort bearbeiten:</Alert>
            <Form>
              <Form.Group className="mb-3" >
                <Form.Label>Neues Passwort</Form.Label>
                <Form.Control type="password" placeholder="Password" value={newSelfPW} onChange={(e)=> setNewSelfPW(e.target.value)} />
              </Form.Group>
              <Form.Group className="mb-3" >
                <Form.Label>Neues Passwort wiederholen</Form.Label>
                <Form.Control type="password" placeholder="Password" value={newRepSelfPW} onChange={(e)=> setNewRepSelfPW(e.target.value)} />
              </Form.Group>
              <div className="Seperator mb-2"></div>
              <Form.Group className="mb-3" >
                <Form.Label>Altes Passwort</Form.Label>
                <Form.Control type="password" placeholder="Password" value={newSelfPwPW} onChange={(e)=> setNewSelfPwPW(e.target.value)} />
              </Form.Group>
              <Button variant="primary" onClick={submitNewSelfPW}>
                Änderung speichern
              </Button>
            </Form>
          </Col>
          <Col>
            <Form>
              <Alert className='mt-1' variant='success'>Benutzer löschen:</Alert>
              <DropdownButton
                className='mb-2 d-grid gap-2'
                as={ButtonGroup}
                key="Warning1"
                id="Dropdown1"
                variant="danger"
                title="Benutzer auswählen"
                onSelect={(ek, e)=>setDelUserSelect(ek)}
              >
                {
                  delUserList.map((item, key) => (
                    <Dropdown.Item eventKey={item.name}>{item.name}</Dropdown.Item>
                  ))
                }
              </DropdownButton>
              <Form.Group className="mb-3" >
                <Form.Label>Admin Passwort 1</Form.Label>
                <Form.Control type="password" placeholder="Password" value={delUserAdmin1} onChange={(e)=>setDelUserAdmin1(e.target.value)} />
              </Form.Group>
              <Form.Group className="mb-3" >
                <Form.Label>Admin Passwort 2</Form.Label>
                <Form.Control type="password" placeholder="Password" value={delUserAdmin2} onChange={(e)=>setDelUserAdmin2(e.target.value)} />
              </Form.Group>
              <Button variant="primary" onClick={submitDelUser}>
                Änderung speichern
              </Button>
            </Form>
          </Col>
          <Col className="mt-1">
            <Alert variant='success'>Neuen Benutzer erstellen:</Alert>
            <Form>
              <Form.Group className="mb-3" >
                <Form.Label>Neue Email Adresse</Form.Label>
                <Form.Control type="email" placeholder="Enter email" value={newMail} onChange={(e)=>setNewMail(e.target.value)} />
              </Form.Group>
              <Form.Group className="mb-3" >
                <Form.Label>Email Adresse wiederholen</Form.Label>
                <Form.Control type="email" placeholder="Enter email" value={newRepMail} onChange={(e)=>setNewRepMail(e.target.value)} />
              </Form.Group>
              <div className="Seperator mb-2"></div>
              <Form.Group className="mb-3" >
                <Form.Label>Passwort</Form.Label>
                <Form.Control type="password" placeholder="Password" value={newPW} onChange={(e)=>setNewPW(e.target.value)} />
              </Form.Group>
              <Form.Group className="mb-3" >
                <Form.Label>Passwort wiederholen</Form.Label>
                <Form.Control type="password" placeholder="Password" value={newRepPW} onChange={(e)=>setNewRepPW(e.target.value)} />
              </Form.Group>
              <div className="Seperator mb-2"></div>
              <Form.Group className="mb-3" >
                <Form.Label>Admin Passwort 1</Form.Label>
                <Form.Control type="password" placeholder="Password" value={newUserAdmin1} onChange={(e)=>setNewUserAdmin1(e.target.value)} />
              </Form.Group>
              <Form.Group className="mb-3" >
                <Form.Label>Admin Passwort 2</Form.Label>
                <Form.Control type="password" placeholder="Password" value={newUserAdmin2} onChange={(e)=>setNewUserAdmin2(e.target.value)} />
              </Form.Group>
              <Button variant="primary" onClick={submitCreateUser}>
                Änderung speichern
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  )
}
